/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	IOperationDefinition
} from '@operation/interfaces/operation-definition.interface';
import {
	IOperationGroupRelationship
} from '@operation/interfaces/operation-group-relationship.interface';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	User
} from '@shared/implementations/users/user';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-create-quote-or-claim',
	templateUrl: './create-quote-or-claim.component.html',
	styleUrls: [
		'./create-quote-or-claim.component.scss']
})

/**
 * A component representing a simple display of a create quote and create claim
 * action.
 *
 * @export
 * @class CreateQuoteOrClaimComponent
 * @implements {IDynamicComponent<Component, any>}
 * @implements {OnInit}
 */
export class CreateQuoteOrClaimComponent
implements IDynamicComponent<Component, any>, OnInit
{
	/**
	 * Initializes a new instance of the create quote or claim component.
	 *
	 * @param {SessionService} sessionService
	 * The session service used to load user data.
	 * @param {OperationService} operationService
	 * The operation service to use when loading operation group data.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service to use when performing operation
	 * commands.
	 * @param {OperationDefinitionApiService} operationDefinitionsApiService
	 * The api service used to load operation definitions data.
	 * @memberof CreateQuoteOrClaimComponent
	 */
	public constructor(
		public sessionService: SessionService,
		public operationService: OperationService,
		public operationExecutionService: OperationExecutionService,
		public operationDefinitionApiService: OperationDefinitionApiService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof CreateQuoteOrClaimComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the value signifying whether or not this component is
	 * loading.
	 *
	 * @type {boolean}
	 * @memberof CreateQuoteOrClaimComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the value signifying whether or not the user has claims
	 * module access.
	 *
	 * @type {boolean}
	 * @memberof CreateQuoteOrClaimComponent
	 */
	public hasClaimsMembership: boolean = false;

	/**
	 * Gets or sets the value signifying whether or not the user has policy
	 * module access.
	 *
	 * @type {boolean}
	 * @memberof CreateQuoteOrClaimComponent
	 */
	public hasPolicyMembership: boolean = false;

	/**
	 * Gets the operation definition name for the create claim operation.
	 *
	 * @type {string}
	 * @memberof CreateQuoteOrClaimComponent
	 */
	public readonly createClaimOperationDefinitionName: string =
		'ClaimCreateClaim';

	/**
	 * Gets the operation definition name for the create quote operation.
	 *
	 * @type {string}
	 * @memberof CreateQuoteOrClaimComponent
	 */
	public readonly createQuoteOperationDefinitionName: string =
		'PolicyCreateQuote';

	/**
	 * Implements the OnInit interface.
	 * This will set the initial values for the component.
	 *
	 * @memberof CreateQuoteOrClaimComponent
	 */
	public ngOnInit(): void
	{
		const user: User =
			new User(this.sessionService.user);

		this.hasClaimsMembership =
			user.hasMembership(
				[
					AppConstants.securityGroups.claimModuleAccess
				]);
		this.hasPolicyMembership =
			user.hasMembership(
				[
					AppConstants.securityGroups.policyModuleAccess
				]);

		this.loading = false;
	}

	/**
	 * Navigates to the selected create quote or claim wizard.
	 *
	 * @async
	 * @param {string} operationDefinitionName
	 * The operation definition name to execute.
	 * @memberof CreateQuoteOrClaimComponent
	 */
	public async createNavigation(
		operationDefinitionName: string): Promise<void>
	{
		const operationDefinition: IOperationDefinition =
			<IOperationDefinition>await this.operationDefinitionApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.name} eq `
						+ `'${operationDefinitionName}'`,
					`${AppConstants.commonProperties.id} `
						+ `${AppConstants.sortDirections.descending}`);

		const postOperationDefinition: IOperationDefinition =
			await this.operationService
				.populateOperationDefinition(
					operationDefinition,
					<IOperationGroupRelationship>{},
					this.context);

		await this.operationExecutionService
			.executeMappedOperation(
				postOperationDefinition);
	}
}