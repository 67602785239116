<ng-template
	[ngIf]="loading"
	[ngIfElse]="Content">
		<div class="ui-g-12 text-center">
			<i
				class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
</ng-template>

<ng-template #Content>
	<div class="ui-g-12 no-padding drill-in-entity-display">
		<div class="ui-g-12 text-left no-padding">

			<div class="ui-g-12 no-padding data-panel">
				<ng-template
					[ngIf]="dynamicFormlyLayout != null">
					<app-dynamic-formly
						[context]="context"
						[dataSet]="commissionsData"
						[initialData]="archivedCommissionsData"
						[layoutSchema]="dynamicFormlyLayout"
						(validityChanged)="validityChanged($event)"
						(valuesChanged)="commissionsValuesChanged($event)">
					</app-dynamic-formly>
				</ng-template>
			</div>

		</div>

		<div class="ui-g-12 no-padding text-right button-set">

			<button pButton
				type="button"
				label="Save"
				(click)="applyCommissionsCorrections()"
				[disabled]="shouldSave === false || saving === true">
			</button>

		</div>
	</div>
</ng-template>