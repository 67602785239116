/* !
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	CommissionsHelper
} from '@insurance/helpers/commissions..helper';
import {
	CommonDashboardComponent
} from '@shared/components/common-dashboard/common-dashboard.component';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	cloneDeep
} from 'lodash-es';

/* eslint-enable max-len */

@Component({
	selector: 'commission-disbursement-summary',
	templateUrl: './commission-disbursement-summary.component.html'
})

/**
 * A component representing a commissions disbursment list component.
 *
 * @export
 * @class CommissionDisbursementSummaryComponent
 * @extends CommonTablePageDirective
 * @implements {IDynamicComponent<Component, any>}
 * @implements {OnInit}
 */
export class CommissionDisbursementSummaryComponent
	extends CommonTablePageDirective
	implements OnInit, IDynamicComponent<CommonDashboardComponent, any>
{
	/**
	 * Initializes an instance of the commission disbursement list component.
	 *
	 * @param {ResolverService} resolver
	 * The resolver service used in this component.
	 * @memberof CommissionDisbursementSummaryComponent
	 */
	public constructor(
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonDashboardComponent, any>}
	 * @memberof CommissionDisbursementSummaryComponent
	 */
	public context: IDynamicComponentContext<CommonDashboardComponent, any>;

	/**
	 * Gets or sets the common table.
	 *
	 * @type {ICommonTable}
	 */
	public commonTable: ICommonTable;

	/**
	 * Gets or sets the selected table filter.
	 *
	 * @type {string}
	 * @memberof CommissionDisbursementSummaryComponent
	 */
	public tableFilter: string = 'All';

	/**
	 * Gets or sets the list of commission disbursements.
	 *
	 * @type {any[]}
	 * @memberof CommissionDisbursementSummaryComponent
	 */
	public disbursements: any[] = [];

	/**
	 * Gets or sets the list of filtered disbursements.
	 *
	 * @type {any[]}
	 * @memberof CommissionDisbursementSummaryComponent
	 */
	public filteredDisbursements: any[] = [];

	/**
	 * Gets or sets the selected row count..
	 *
	 * @type {number}
	 * @memberof CommissionDisbursementSummaryComponent
	 */
	public selectedRowCount: number = 5;

	/**
	 * Gets or sets the selected agency.
	 *
	 * @type {any}
	 * @memberof CommissionDisbursementSummaryComponent
	 */
	public selectedAgency: any;

	/**
	 * Gets the set of quick filters for this component.
	 *
	 * @type {object}
	 * @memberof CommissionDisbursementSummaryComponent
	 */
	public readonly quickfilters:
		{
			all: string;
			positive: string;
			negative: string;
			zero: string;
			ach: string;
			check: string;
		} = {
			all: 'All',
			positive: 'Positive',
			negative: 'Negative',
			zero: 'Zero',
			ach: 'ACH',
			check: 'Check'
		};

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof CommissionDisbursementSummaryComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.context.data =
			this.context.source.entityContext.data.entityInstance;
		this.disbursements =
			cloneDeep(this.context.data.data.disbursements);

		this.setCommissionDisbursementCalculatedValues();
		this.setupTableDefinitions();
		this.setfilteredDisbursements();

		this.loadingTableDefinitions = false;
	}

	/**
	 * Sets the commission disbursement calculated values for commissions and
	 * adjustments amounts.
	 *
	 * @async
	 * @memberof CommissionDisbursementSummaryComponent
	 */
	public setCommissionDisbursementCalculatedValues(): void
	{
		const commissionDisbursementEntityInstance: IEntityInstance =
			this.context.data;

		this.disbursements.forEach(
			(disbursement: any) =>
			{
				const totalCommissions = CommissionsHelper
					.getCommissionDisbursementCommissionsByAgencyId(
						commissionDisbursementEntityInstance,
						disbursement.organizationId);

				const totalAdjustments = CommissionsHelper
					.getCommissionDisbursementAdjustmentsByAgencyId(
						commissionDisbursementEntityInstance,
						disbursement.organizationId);

				disbursement.commissionsAmount = totalCommissions;
				disbursement.adjustmentsAmount = totalAdjustments;
			});
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @memberof CommissionDisbursementSummaryComponent
	 */
	public setupTableDefinitions(): void
	{
		this.availableColumns =
			<ICommonTableColumn[]>
			[
				{
					dataKey: 'organizationName.displayName',
					columnHeader: 'Agency',
					displayOrder: 1
				},
				{
					dataKey: 'organizationDisbursementMethod',
					columnHeader: 'Disbursement Method',
					displayOrder: 2
				},
				{
					dataKey: 'account',
					columnHeader: 'Account',
					displayOrder: 3
				},
				{
					dataKey: 'commissionsAmount',
					columnHeader: 'Commissions',
					dataFormatType: 'Currency',
					displayOrder: 4
				},
				{
					dataKey: 'adjustmentsAmount',
					columnHeader: 'Adjustments',
					dataFormatType: 'Currency',
					displayOrder: 5
				},
				{
					dataKey: 'total',
					columnHeader: 'Amount',
					dataFormatType: 'Currency',
					displayOrder: 6
				}
			];
		this.selectedColumns =
			[
				...this.availableColumns
			];

		this.commonTable =
			{
				tableTitle: 'Agencies',
				dataKey: 'organizationId',
				exportable: true,
				tableHeight: {
					virtualPageSizeBased: true,
					minimumDrawerItemHeight: 9
				},
				actions: {
					filter: {
						quickFilters:
							[
								{
									label: this.quickfilters.all,
									value: this.quickfilters.all
								},
								{
									label: this.quickfilters.positive,
									value: this.quickfilters.positive
								},
								{
									label: this.quickfilters.negative,
									value: this.quickfilters.negative
								},
								{
									label: this.quickfilters.zero,
									value: this.quickfilters.zero
								},
								{
									label: this.quickfilters.ach,
									value: this.quickfilters.ach
								},
								{
									label: this.quickfilters.check,
									value: this.quickfilters.check
								}
							],
						selectedFilterValue: this.tableFilter
					}
				},
				objectSearch: {
					filter: this.tableFilter,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise:
					() =>
					{
						this.setfilteredDisbursements();

						return Promise.resolve(this.filteredDisbursements);
					},
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext:
					(commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
					{
						this.commonTableContext = commonTableContext;
						this.selectedAgency = commonTableContext.data;
					}
			};
	}

	/**
	 * Sets the filtered commission disbursements.
	 *
	 * @memberof CommissionDisbursementSummaryComponent
	 */
	private setfilteredDisbursements(): void
	{
		switch (this.commonTable.objectSearch.filter)
		{
			case this.quickfilters.positive:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement =>
							disbursement.total > 0);
				break;
			case this.quickfilters.negative:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement =>
							 disbursement.total < 0);
				break;
			case this.quickfilters.zero:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement =>
							disbursement.total === 0);
				break;
			case this.quickfilters.ach:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement =>
							disbursement
								.organizationDisbursementMethod === 'ACH');
				break;
			case this.quickfilters.check:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement =>
							disbursement
								.organizationDisbursementMethod === 'Check');
				break;
			default:
				this.filteredDisbursements =
					this.disbursements;
		}
	}
}