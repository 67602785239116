<form [formGroup]="loginForm" (ngSubmit)="login()">
	<div class="ui-g">
		<div class="ui-g-3 icon-login"
			style="text-align:left;">
			<img src= "../assets/layout/images/login/icon-login.svg"
				alt="Login Icon">
		</div>
		<div class="ui-g-9" style="text-align:right;">
			<h2 class="welcome-text">
				Welcome Guest
			</h2>
			<span class="guest-sign-in">
				Log in to {{brandingName}}
			</span>
		</div>
		<div class="ui-g-12" style="text-align:left;">
			<label for="userName"
				class="login-label">
				Username
			</label>
			<div class="login-input">
				<input
					pInputText
					type="text"
					#userName
					id="userName"
					formControlName="userName"
					minlength="3"
					required="required" />
			</div>
		</div>
		<div class="ui-g-12" style="text-align:left;">
			<label for="password"
				class="login-label">
				Password
			</label>
			<div class="login-input">
				<input
					pInputText
					type="password"
					#password
					id="password"
					formControlName="password"
					required="required"
					minlength="1"
					[@shakeAnimation]="loginForm.invalid" />
			</div>
		</div>
		<div class="ui-g-12 ui-md-6 button-pane">
			<button
				pButton
				type="submit"
				label="Log in"
				aria-disabled="false"
				class="p-button-primary"
				[disabled]="loginIsDisabled">
				<div
					class="loading-indicator"
					*ngIf="this.submitInProgress">
					<p-progressSpinner
						strokeWidth="5">
					</p-progressSpinner>
				</div>
			</button>
		</div>
		<div class="ui-g-12 ui-md-6 link-pane">
			<a id="ForgotPasswordLink"
				class="text-link forgot-password-link"
				(click)="displayResetUser()">
				Forgot Password?
			</a>
		</div>
	</div>
</form>
