/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	CurrencyPipe,
	DecimalPipe,
	PercentPipe
} from '@angular/common';
import {
	Inject,
	Injectable,
	LOCALE_ID
} from '@angular/core';
import {
	IEntitySearch
} from '@entity/interfaces/entity-search.interface';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	saveAs
} from 'file-saver';
import {
	JSONPath
} from 'jsonpath-plus';
import {
	cloneDeep,
	set
} from 'lodash-es';
import * as XLSX from 'xlsx';

/* eslint-enable max-len */

/**
 * A singleton class representing a data export service.
 *
 * @export
 * @class DataExportService
 */
@Injectable({
	providedIn: 'root'
})
export class DataExportService
{
	/**
	 * Initializes a new instance of the DataExportService class.
	 *
	 * @param {string} locale
	 * The locale as set by the application.
	 * @memberof DataExportService
	 */
	public constructor(
		@Inject(LOCALE_ID) private readonly locale: string)
	{
		this.currencyPipe =
			new CurrencyPipe(this.locale);
		this.decimalPipe =
			new DecimalPipe(this.locale);
		this.percentPipe =
			new PercentPipe(this.locale);
	}

	/**
	 * The currency pipe.
	 *
	 * @type {CurrencyPipe}
	 * @memberof DataExportService
	 */
	private readonly currencyPipe: CurrencyPipe;

	/**
	 * The decimal pipe.
	 *
	 * @type {DecimalPipe}
	 * @memberof DataExportService
	 */
	private readonly decimalPipe: DecimalPipe;

	/**
	 * The percent pipe.
	 *
	 * @type {PercentPipe}
	 * @memberof DataExportService
	 */
	private readonly percentPipe: PercentPipe;

	/**
	 * Exports data as a comma separated values .csv file.
	 *
	 * @param {string} fileName
	 * The file name. IE: `${fileName}.csv`.
	 * @param {any[]} data
	 * The data to export.
	 * @memberof DataExportService
	 */
	public exportDataAsCommaSeparatedValues(
		fileName: string,
		data: any[]): void
	{
		const commaSeparatedValues: string =
			this.transformToCommaSeparatedValues(
				data);

		const byteOrderMark = '\uFEFF';
		const blob: Blob =
		 	new Blob(
		 		[
		 			byteOrderMark,
		 			commaSeparatedValues
		 		],
		 		{
		 			type: 'text/csv;charset=utf-8'
		 		});

		saveAs(
			blob,
			`${fileName}.csv`);
	}

	/**
	 * Exports data as an Excel Workboook .xslx file.
	 *
	 * @param {string} fileName
	 * The file name. IE: `${fileName}.xslx`.
	 * @param {any[]} data
	 * The data to export.
	 * @memberof DataExportService
	 */
	public exportDataAsExcel(
		fileName: string,
		data: any[]): void
	{
		const worksheet: XLSX.WorkSheet =
			XLSX.utils.json_to_sheet(
				data);
		const workbook: XLSX.WorkBook =
			XLSX.utils.book_new();

		XLSX.utils.book_append_sheet(
			workbook,
			worksheet,
			'Exported Data');

		const excelBuffer: any =
			XLSX.write(
				workbook,
				{
					bookType: 'xlsx',
					type: 'array'
				});
		const blob: Blob =
			new Blob(
				[
					excelBuffer
				],
				{
					type: 'application/octet-stream'
				});

		saveAs(
			blob,
			`${fileName}.xlsx`);
	}

	/**
	 * Exports table data.
	 *
	 * @async
	 * @param {string} fileName
	 * The file name. IE: `${fileName}.${exportType}`.
	 * @param {string} exportType
	 * The export type.
	 * @param {any[]} data
	 * The data to export.
	 * @param {ICommonTableColumn[]} columns
	 * The columns selected for exports.
	 * @param {IDynamicComponentContext<CommonTableComponent, any>} pageContext
	 * The table page context.
	 * @memberof DataExportService
	 */
	public async exportTableData(
		fileName: string,
		exportType: string,
		data: any[],
		columns: ICommonTableColumn[],
		pageContext: IDynamicComponentContext<CommonTableComponent, any>):
		Promise<void>
	{
		const filteredData: any[] =
			this.filterAndTransformTableData(
				data,
				columns,
				pageContext);

		switch (exportType)
		{
			case AppConstants.exportTypes.excel:
				this.exportDataAsExcel(
					fileName,
					filteredData);
				break;
			case AppConstants.exportTypes.commaSeparatedValues:
			default:
				this.exportDataAsCommaSeparatedValues(
					fileName,
					filteredData);
				break;
		}
	}

	/**
	 * Exports all table data.
	 *
	 * @async
	 * @param {string} fileName The file name.
	 * The file name. IE: `${fileName}.${exportType}`.
	 * @param {string} exportType
	 * The export type.
	 * @param {
	 * 	(objectSearch?: IObjectSearch | IEntitySearch) => Promise<any>
	 * } dataPromise
	 * The data promise used to load all table data.
	 * @param {(IObjectSearch | IEntitySearch)} searchObject
	 * The search object.
	 * @param {ICommonTableColumn[]} columns
	 * The columns selected for exports.
	 * @param {IDynamicComponentContext<CommonTableComponent, any>} pageContext
	 * The table page context.
	 * @memberof DataExportService
	 */
	public async exportAllTableData(
		fileName: string,
		exportType: string,
		dataPromise:
			(objectSearch?: IObjectSearch | IEntitySearch)
				=> Promise<any>,
		searchObject: IObjectSearch | IEntitySearch,
		columns: ICommonTableColumn[],
		pageContext: IDynamicComponentContext<CommonTableComponent, any>):
		Promise<void>
	{
		let data: any[] =
			await dataPromise(
				searchObject);

		if (data.length < searchObject.limit)
		{
			await this.exportTableData(
				fileName,
				exportType,
				data,
				columns,
				pageContext);

			return;
		}

		const searchObjectClone: IObjectSearch | IEntitySearch =
			cloneDeep(searchObject);

		let offset: number =
			searchObjectClone.limit;
		let fullDataset: any[] = data;
		let fullDatasetGathered: boolean = false;

		while (fullDatasetGathered === false)
		{
			searchObjectClone.offset = offset;

			data =
				await dataPromise(
					searchObjectClone);

			fullDataset =
				fullDataset.concat(
					data);

			if (data.length < searchObjectClone.limit)
			{
				fullDatasetGathered = true;
			}

			offset += searchObjectClone.limit;
		}

		await this.exportTableData(
			fileName,
			exportType,
			fullDataset,
			columns,
			pageContext);
	}

	/**
	 * Filters and transforms table data.
	 *
	 * @param {any[]} data
	 * The data to filter and transform.
	 * @param {ICommonTableColumn[]} columns
	 * The column definitions to filter to.
	 * @param {IDynamicComponentContext<CommonTableComponent, any>} pageContext
	 * The table page context.
	 * @returns {any[]}
	 * The filtered and transformed data.
	 * @memberof DataExportService
	 */
	private filterAndTransformTableData(
		data: any[],
		columns: ICommonTableColumn[],
		pageContext: IDynamicComponentContext<CommonTableComponent, any>): any[]
	{
		columns
			.filter(
				(column: ICommonTableColumn) =>
					column.dataFormatType !== AppConstants.dataFormatTypes.icon
						&& column.displayOrder !== AppConstants
							.reservedTableDisplayOrders.actionColumn)
			.sort(
				(columnOne: ICommonTableColumn,
					columnTwo: ICommonTableColumn) =>
					columnOne.displayOrder - columnTwo.displayOrder);

		return data.map(
			(item: any) =>
				columns.reduce(
					(accumulator: any,
						tableColumn: ICommonTableColumn) =>
					{
						set(
							accumulator,
							tableColumn.columnHeader,
							this.formatColumn(
								item,
								tableColumn,
								pageContext));

						return accumulator;
					},
					{}));
	}

	/**
	 * Formats a column value.
	 *
	 * @param {any} item
	 * The item to format.
	 * @param {ICommonTableColumn} tableColumn
	 * The table column to format.
	 * @param {IDynamicComponentContext<CommonTableComponent, any>} pageContext
	 * The table page context.
	 * @returns {any}
	 * The formatted column value.
	 * @memberof DataExportService
	 */
	private formatColumn(
		item: any,
		tableColumn: ICommonTableColumn,
		pageContext: IDynamicComponentContext<CommonTableComponent, any>): any
	{
		const columnValue: any =
			JSONPath(
				{
					path: tableColumn.dataKey,
					json: item
				})[0];

		if (!AnyHelper.isNullOrWhitespace(tableColumn.dataFunction))
		{
			return StringHelper.transformToFunction(
				tableColumn.dataFunction,
				pageContext)(columnValue);
		}

		if (AnyHelper.isNullOrWhitespace(columnValue))
		{
			return AppConstants.empty;
		}

		return this.getFormattedColumn(
			columnValue,
			tableColumn);
	}

	/**
	 * Gets a formatted column.
	 *
	 * @param {any} columnValue
	 * The column value to format.
	 * @param {ICommonTableColumn} tableColumn
	 * The table column to format.
	 * @returns {any}
	 * The formatted column output.
	 * @memberof DataExportService
	 */
	private getFormattedColumn(
		columnValue: any,
		tableColumn: ICommonTableColumn): any
	{
		if (AnyHelper.isNullOrWhitespace(tableColumn.dataFormatType))
		{
			return columnValue;
		}

		switch(tableColumn.dataFormatType)
		{
			case AppConstants.dataFormatTypes.date:
			case AppConstants.dataFormatTypes.shortDate:
				return StringHelper.format(
					columnValue,
					AppConstants.formatTypes.shortDate);

			case AppConstants.dataFormatTypes.dateTime:
			case AppConstants.dataFormatTypes.longDate:
				return StringHelper.format(
					columnValue,
					AppConstants.formatTypes.longDate);

			case  AppConstants.dataFormatTypes.currency:
				return this.currencyPipe
					.transform(
						columnValue,
						AppConstants.localeDefinitions.unitedStatesDollars,
						AppConstants.localeDefinitions.symbol,
						tableColumn.formattedRoundingPrecision);

			case  AppConstants.dataFormatTypes.decimal:
				return this.decimalPipe
					.transform(
						columnValue,
						tableColumn.formattedRoundingPrecision);

			case  AppConstants.dataFormatTypes.percent:
				return this.percentPipe
					.transform(
						columnValue,
						tableColumn.formattedRoundingPrecision);

			default:
				return columnValue;
		}
	}

	/**
	 * Transforms data to a collection of comma separated values ready for
	 * display in a .csv file.
	 *
	 * @param {any[]} data
	 * The data to transform.
	 * @returns {string}
	 * The comma separated values array.
	 * @memberof DataExportService
	 */
	private transformToCommaSeparatedValues(
		data: any[]): string
	{
		if (data.length === 0)
		{
			return AppConstants.empty;
		}

		const headers: string[] =
			Object.keys(data[0]);

		const csvRows: string[] =
			data.map(
				(row: any) =>
					headers.map(
						(header: string) =>
						{
							const cell: any =
								row[header];
							const stringData: boolean =
								typeof cell ===
									AppConstants.propertyTypes.string;

							return stringData === true
								? `"${cell.replace(/"/g, '""')}"`
								: cell;
						})
						.join(
							AppConstants.characters.comma));

		const csvString: string =
			[headers.join(
				AppConstants.characters.comma),
			...csvRows]
				.join(
					`${AppConstants.characters.carriageReturn}`
						+ `${AppConstants.characters.lineFeed}`);

		return csvString;
	}
}