/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Injectable
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	BaseApiService
} from '@api/services/base/base.api.service';
import {
	DynamicComponentLookup
} from '@dynamicComponents/dynamic-component.lookup';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	PermissionConstants
} from '@shared/constants/permission.constants';
import {
	ReportConstants
} from '@shared/constants/report.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	JsonSchemaLayout
} from '@shared/implementations/application-data/json-schema-layout';
import {
	DisplayComponentInstance
} from '@shared/implementations/display-components/display-component-instance';
import {
	User
} from '@shared/implementations/users/user';
import {
	IActionTableConfiguration
} from '@shared/interfaces/application-objects/actions-table-configuration.interface';
import {
	IChartDefinition
} from '@shared/interfaces/application-objects/chart-definition.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDashboardSection
} from '@shared/interfaces/application-objects/dashboard-section.interface';
import {
	IDashboardWidget
} from '@shared/interfaces/application-objects/dashboard-widget.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicLayoutConfiguration
} from '@shared/interfaces/application-objects/dynamic-layout-configuration.interface';
import {
	IInformationMenuItem
} from '@shared/interfaces/application-objects/information-menu-item.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ITableExpandDefinition
} from '@shared/interfaces/application-objects/table-expand-definition.interface';
import {
	IWizardStepMenuItem
} from '@shared/interfaces/application-objects/wizard-step-menu-item.interface';
import {
	IChartContext
} from '@shared/interfaces/dynamic-interfaces/chart-context.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IWizardStep
} from '@shared/interfaces/dynamic-interfaces/wizard-step-context.interface';
import {
	IReallySimpleSyndicationConfiguration
} from '@shared/interfaces/really-simple-syndication/really-simple-syndication-cofiguration.interface';
import {
	IPowerBiReportDefinition
} from '@shared/interfaces/reports/power-bi/power-bi-report-definition.interface';
import {
	IPowerBiReportLookup
} from '@shared/interfaces/reports/power-bi/power-bi-report-lookup.interface';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	PowerBiApiService
} from '@shared/services/power-bi-api.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	IEmbedConfiguration
} from 'powerbi-client';

/* eslint-enable max-len */

/**
 * A class representing a singleton display component factory.
 * This is used to create component interfaces from a display
 * component instance.
 *
 * @export
 * @class DisplayComponentFactory
 */
@Injectable({
	providedIn: 'root'
})
export class DisplayComponentFactory
{
	/**
	 * Creates an instance of a display component factory.
	 *
	 * @param {DisplayComponentService} displayComponentService
	 * The service used for display component lookups.
	 * @param {ResolverService} resolver
	 * The resolver service used for generic lookups.
	 * @param {SessionService} sessionService
	 * The session service used for factory permission based logic.
	 * @param {PowerBiApiService} powerBiApiService
	 * The external power bi api service.
	 * @memberof DisplayComponentFactory
	 */
	public constructor(
		public displayComponentService: DisplayComponentService,
		public resolver: ResolverService,
		public sessionService: SessionService,
		public powerBiApiService: PowerBiApiService,
		public router: Router,
		public moduleService: ModuleService)
	{
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof BaseExpandComponent
	 */
	public commonTableContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Creates and returns a dashboard section ready for display from the sent
	 * display component instance.
	 *
	 * @param {DisplayComponentInstance} displayComponentInstance
	 * The display component instance to be instantiated as a dashboard section.
	 * @param {IDynamicComponentContext<Component, any>} dynamicComponentContext
	 * The dynamic component context for this dashboard section.
	 * @returns {IDashboardSection}
	 * A dashboard section ready for display.
	 * @memberof DisplayComponentFactory
	 */
	public dashboardSection(
		displayComponentInstance: DisplayComponentInstance,
		dynamicComponentContext:
			IDynamicComponentContext<Component, any>): IDashboardSection
	{
		const componentDefinition: any =
			displayComponentInstance.displayComponentDefinition
				.jsonDefinition;

		return <IDashboardSection>
			{
				displayComponentInstance: displayComponentInstance,
				label: componentDefinition.label,
				order: displayComponentInstance.order,
				parameterLayoutSchema:
					this.getParameterLayoutSchema(
						displayComponentInstance.jsonParameterDefinition,
						displayComponentInstance.jsonParameterLayout,
						dynamicComponentContext),
				parameterLayoutData:
					this.getParameterData(
						dynamicComponentContext.data),
				widgets: []
			};
	}

	/**
	 * Creates and returns a dashboard widget ready for display from the sent
	 * display component instance.
	 *
	 * @async
	 * @param {DisplayComponentInstance} displayComponentInstance
	 * The display component instance to be instantiated as a dashboard widget.
	 * @param {IDynamicComponentContext<Component, any>} dynamicComponentContext
	 * The dynamic component context for this dashboard widget.
	 * @param {boolean} includeSecondaryDisplay
	 * If true this will also load and prepare a secondary display. The default
	 * value is true.
	 * @returns {Promise<IInformationMenuItem<any>>}
	 * An awaitable dashboard widget ready for display.
	 * @memberof DisplayComponentFactory
	 */
	public async dashboardWidget(
		displayComponentInstance: DisplayComponentInstance,
		dynamicComponentContext: IDynamicComponentContext<Component, any>,
		includeSecondaryDisplay: boolean = true): Promise<IDashboardWidget>
	{
		const widgetDefinition: any =
			displayComponentInstance.displayComponentDefinition
				.jsonDefinition;

		const widgetDisplayComponentInstance: DisplayComponentInstance =
			await this.displayComponentService
				.populateDisplayComponentInstance(
					widgetDefinition?.displayComponent,
					dynamicComponentContext);

		if (AnyHelper.isNull(widgetDisplayComponentInstance))
		{
			return null;
		}

		const parameterLayoutSchema: any =
			this.getParameterLayoutSchema(
				widgetDisplayComponentInstance.jsonParameterDefinition,
				widgetDisplayComponentInstance.jsonParameterLayout,
				dynamicComponentContext);

		dynamicComponentContext.data =
			await this.getMergedInitialParameterData(
				widgetDisplayComponentInstance.jsonInitialParameters,
				dynamicComponentContext);

		const widgetData: IDynamicComponentContext<Component, any> =
			await this.getDynamicComponentContext(
				widgetDisplayComponentInstance,
				dynamicComponentContext);

		return this.getDashboardWidget(
			widgetDisplayComponentInstance
				?.displayComponentDefinition
				.parsedJson
				.definition
				.dynamicComponent
				?? widgetDisplayComponentInstance
					.displayComponentDefinition
					.componentName,
			includeSecondaryDisplay,
			widgetDefinition,
			parameterLayoutSchema,
			dynamicComponentContext,
			displayComponentInstance,
			widgetDisplayComponentInstance,
			widgetData);
	}

	/**
	 * Creates and returns an external ready for display from the sent
	 * display component instance.
	 *
	 * @async
	 * @param {DisplayComponentInstance} displayComponentInstance
	 * The display component instance to be instantiated as an external report.
	 * @param {IDynamicComponentContext<Component, any>} dynamicComponentContext
	 * The dynamic component context for this simple table.
	 * @param {boolean} isViewOnly
	 * If false, this will display the external report in edit mode. This
	 * value defaults to true.
	 * @returns {Promise<IPowerBiReportDefinition>}
	 * An awaitable external report ready for display.
	 * @memberof DisplayComponentFactory
	 */
	public async externalReport(
		displayComponentInstance: DisplayComponentInstance,
		dynamicComponentContext: IDynamicComponentContext<Component, any>,
		isViewOnly: boolean = true): Promise<IPowerBiReportDefinition>
	{
		const componentDefinition: IPowerBiReportDefinition =
			displayComponentInstance.displayComponentDefinition
				.jsonDefinition;

		switch (componentDefinition.externalReportType)
		{
			case ReportConstants.externalReportTypes.powerBi:
				if (AnyHelper.isNullOrWhitespace(
					componentDefinition.reportId))
				{
					const reportLookup: IPowerBiReportLookup =
						await this.powerBiApiService
							.lookupReportData(
								componentDefinition);

					componentDefinition.reportId = reportLookup.reportId;
					componentDefinition.datasetId = reportLookup.datasetId;
				}

				componentDefinition.reportFilter =
					AnyHelper.isNullOrWhitespace(
						componentDefinition.reportFilter)
						? AppConstants.empty
						: StringHelper.transformToFunction(
							componentDefinition.reportFilter,
							dynamicComponentContext)();

				componentDefinition.viewOnly = isViewOnly;
				const embedConfiguration: IEmbedConfiguration =
					<IEmbedConfiguration>
					await this.powerBiApiService
						.populatePowerBiEmbedConfiguration(
							componentDefinition);

				return <IPowerBiReportDefinition>
				{
					datasetId: componentDefinition.datasetId,
					datasetGroupId: componentDefinition.datasetGroupId,
					embedConfiguration: embedConfiguration,
					externalReportType: componentDefinition.externalReportType,
					groupId: componentDefinition.groupId,
					pageName: componentDefinition.pageName,
					reportFilter: componentDefinition.reportFilter,
					reportId: componentDefinition.reportId,
					reportName: componentDefinition.reportName,
					reportType: componentDefinition.reportType,
					tileId: componentDefinition.tileId,
					viewOnly: componentDefinition.viewOnly,
					visualName: componentDefinition.visualName,
					workspaceType:  componentDefinition.workspaceType
				};
			default:
				throw new Error(
					`${componentDefinition.externalReportType} `
						+ 'requires a factory method.');
		}
	}

	/**
	 * Creates and returns a simple table ready for display from the sent
	 * display component instance.
	 *
	 * @async
	 * @param {DisplayComponentInstance} displayComponentInstance
	 * The display component instance to be instantiated as a simple table.
	 * @param {IDynamicComponentContext<Component, any>} dynamicComponentContext
	 * The dynamic component context for this simple table.
	 * @returns {Promise<ICommonTable>}
	 * An awaitable common table ready for display.
	 * @memberof DisplayComponentFactory
	 */
	public async simpleDataPromiseTable(
		displayComponentInstance: DisplayComponentInstance,
		dynamicComponentContext:
			IDynamicComponentContext<Component, any>): Promise<ICommonTable>
	{
		let displayColumnCounter: number = 1;
		const componentDefinition: any =
			displayComponentInstance.displayComponentDefinition
				.jsonDefinition;

		let dataFilter: string = AppConstants.empty;
		if (!AnyHelper.isNullOrWhitespace(componentDefinition.dataFilter))
		{
			dataFilter =
				StringHelper.transformToFunction(
					componentDefinition.dataFilter,
					dynamicComponentContext)();
		}

		const availableColumns: ICommonTableColumn[] =
			componentDefinition.columns
				.map((displayColumn: ICommonTableColumn) =>
				{
					displayColumn.displayOrder =
						displayColumnCounter++;

					return displayColumn;
				});

		const commonTableObject: ICommonTable =
			<ICommonTable>
			{
				tableTitle: componentDefinition.title,
				navigationAccess: componentDefinition.navigationAccess,
				nestedTable: componentDefinition.nestedTable ?? false,
				hideSettings: true,
				exportable:
					componentDefinition.exportable ?? false,
				exportAllowedPromise:
					componentDefinition.exportAllowedPromise
						?? (async() => Promise.resolve(true)),
				objectSearch:
					<IObjectSearch>
					{
						filter: dataFilter,
						orderBy: componentDefinition.dataOrderBy,
						offset: 0,
						limit: componentDefinition.dataLimit,
						virtualIndex: 0,
						virtualPageSize: componentDefinition.pageSize
					},
				apiPromise:
					async(objectSearch: IObjectSearch) =>
					{
						if (AnyHelper.isNullOrEmpty(
							componentDefinition.dataApiPromise))
						{
							const apiService: BaseApiService =
									this.resolver.resolveApiService(
										componentDefinition.dataApiService);

							return (<BaseEntityApiService<any>>apiService)
								.query(
									objectSearch.filter,
									objectSearch.orderBy,
									objectSearch.offset,
									objectSearch.limit);
						}
						else
						{
							return StringHelper
								.transformToFunction(
									componentDefinition.dataApiPromise,
									dynamicComponentContext)(objectSearch);
						}
					},
				availableColumns: availableColumns,
				selectedColumns:
					<ICommonTableColumn[]>
					[
						...availableColumns
					],
				commonTableContext:
					(commonTableContext:
						IDynamicComponentContext<CommonTableComponent, any>) =>
					{
						this.commonTableContext = commonTableContext;
					},
				actions:
					<IActionTableConfiguration>
					{
					}
			};

		if (!AnyHelper.isNull(
			componentDefinition.quickFilters))
		{
			commonTableObject.actions.filter =
				{
					quickFilters: componentDefinition.quickFilters,
					selectedFilterValue: dataFilter
				};
		}

		if (!AnyHelper.isNull(
			componentDefinition.drillInCommand))
		{
			commonTableObject.actions.drillIn =
				<ITableExpandDefinition>
				{
					command: () =>
					{
						StringHelper
							.transformToFunction(
								componentDefinition.drillInCommand,
								dynamicComponentContext)(
								commonTableObject.getCommonTableContext());
					}
				};
		}

		if (!AnyHelper.isNull(
			componentDefinition.tableFixedHeight))
		{
			commonTableObject.tableHeight =
				{
					virtualPageSizeBased: false,
					explicitHeight:
						componentDefinition.tableFixedHeight ===
							AppConstants.commonTableAttributes
								.standardWidgetTableHeight
							? AppConstants.staticLayoutSizes
								.standardCommonTableWidgetHeight
							: componentDefinition.tableFixedHeight,
					minimumDrawerItemHeight: 2
				};
		}

		if (!AnyHelper.isNull(
			componentDefinition.itemSelectionCommand))
		{
			commonTableObject.actions.view =
				<ITableExpandDefinition>
				{
					disabledExpandItem: true,
					items: [
						{
							command: () =>
							{
								StringHelper
									.transformToFunction(
										componentDefinition
											.itemSelectionCommand,
										dynamicComponentContext)(
										commonTableObject
											.getCommonTableContext());
							}
						}
					]
				};
		}

		return commonTableObject;
	}

	/**
	 * Creates and returns a summary card ready for display from the sent
	 * display component instance.
	 *
	 * @async
	 * @param {DisplayComponentInstance} displayComponentInstance
	 * The display component instance to be instantiated as a summary card.
	 * @param {IDynamicComponentContext<Component, any>} dynamicComponentContext
	 * The dynamic component context for this summary card.
	 * @returns {Promise<IInformationMenuItem<any>>}
	 * An awaitable information menu ready for display.
	 * @memberof DisplayComponentFactory
	 */
	public async summaryCard(
		displayComponentInstance: DisplayComponentInstance,
		dynamicComponentContext: IDynamicComponentContext<Component, any>):
		Promise<IInformationMenuItem<any>>
	{
		if (AnyHelper.isNull(displayComponentInstance))
		{
			return null;
		}

		const componentDefinition: any =
			displayComponentInstance.displayComponentDefinition
				.jsonDefinition;

		let overlayDisplayComponent: DisplayComponentInstance;
		let overlayDisplayDefinition: any;
		let overlayDisplay: boolean = false;

		if (!AnyHelper.isNullOrWhitespace(
			componentDefinition.overlayDisplayComponent))
		{
			overlayDisplayComponent =
				await this.displayComponentService
					.populateDisplayComponentInstance(
						componentDefinition.overlayDisplayComponent,
						dynamicComponentContext);

			overlayDisplay = !AnyHelper.isNull(overlayDisplayComponent);
			if (overlayDisplay === true)
			{
				overlayDisplayDefinition =
					overlayDisplayComponent
						.displayComponentDefinition
						.jsonDefinition;
			}
		}

		const chartDisplay: boolean =
			!AnyHelper.isNullOrWhitespace(
				componentDefinition.chartDisplayComponent);
		const overlayChartDisplay: boolean =
			!AnyHelper.isNullOrWhitespace(
				overlayDisplayDefinition?.chartConfiguration);
		const groupByCount: boolean =
			!AnyHelper.isNull(componentDefinition.groupByCount)
				&& componentDefinition.groupByCount === true;

		let overlayDisplayContext: IDynamicComponentContext<Component, any> =
			null;

		if (overlayDisplay === true)
		{
			overlayDisplayContext =
				overlayChartDisplay === false
					? <IDynamicComponentContext<Component, any>>
						{
							source: dynamicComponentContext.source,
							data: await StringHelper
								.transformToDataPromise(
									overlayDisplayComponent
										.displayComponentDefinition
										.jsonDefinition.dataPromise,
									dynamicComponentContext)
						}
					: <IDynamicComponentContext<
						Component,
						IChartContext<any>>>
						{
							source:
								dynamicComponentContext.source,
							data: {
								chartDefinition:
									await this.getChartDefinition(
										overlayDisplayDefinition,
										dynamicComponentContext),
								data:
									overlayDisplayDefinition
										.initialData,
								fillMissingDataSets:
									overlayDisplayDefinition
										.fillMissingDataSets,
								maintainAspectRatio: true
							}
						};
		}

		if (!AnyHelper.isNullOrEmpty(componentDefinition.withPromise))
		{
			componentDefinition.width =
				await StringHelper.transformToDataPromise(
					componentDefinition.withPromise,
					dynamicComponentContext);
		}

		return <IInformationMenuItem<any>>
			{
				dataPromise:
					chartDisplay === false
						? StringHelper.transformToDataPromise(
							componentDefinition.dataPromise,
							dynamicComponentContext)
						: null,
				chartDefinition:
					chartDisplay === true
						? await this.populateChartDisplayComponent(
							componentDefinition.chartDisplayComponent,
							dynamicComponentContext)
						: null,
				summaryTemplate:
					groupByCount === false
						&& chartDisplay === false
						? componentDefinition.summaryTemplate
						: null,
				summaryFunction:
					groupByCount === false
						&& chartDisplay === false
						? componentDefinition.summaryFunction
						: null,
				summaryPromise:
					groupByCount === false
						&& chartDisplay === false
						? componentDefinition.summaryPromise
						: null,
				titleTemplate:
					componentDefinition.titleTemplate,
				titlePromise:
					componentDefinition.titlePromise,
				groupByCount:
					groupByCount,
				width:
					componentDefinition.width,
				overlayDynamicComponent:
					overlayDisplayComponent
						?.displayComponentDefinition.componentName,
				overlayDynamicContext:
					overlayDisplayContext
			};
	}

	/**
	 * Creates and returns a reallySimpleSyndicationFeed
	 * ready for display from the sent display component instance.
	 *
	 * @param {DisplayComponentInstance} displayComponentInstance
	 * The display component instance to be instantiated as a rss feed.
	 * @returns {Promise<IReallySimpleSyndicationConfiguration>}
	 * The rss feed ready to display.
	 * @memberof DisplayComponentFactory
	 */
	 public async reallySimpleSyndicationFeed(
		displayComponentInstance: DisplayComponentInstance):
		Promise<IReallySimpleSyndicationConfiguration>
	{
		if (AnyHelper.isNull(displayComponentInstance))
		{
			return null;
		}

		const componentDefinition: any =
			displayComponentInstance.displayComponentDefinition
				.jsonDefinition;

		return <IReallySimpleSyndicationConfiguration> {
			feedSourceUrl: componentDefinition.feedSourceUrl
		};
	}

	/**
	 * Creates and returns a dynamic layout ready for display from the sent
	 * display component instance.
	 *
	 * @param {DisplayComponentInstance} displayComponentInstance
	 * The display component instance to be instantiated as a dynamic layout.
	 * @param {IDynamicComponentContext<Component, any>} dynamicComponentContext
	 * The dynamic component context for this dynamic component interface.
	 * @returns {Promise<IDynamicLayoutConfiguration>}
	 * A dynamic layout ready for display.
	 * @memberof DisplayComponentFactory
	 */
	public async dynamicLayout(
		displayComponentInstance: DisplayComponentInstance,
		dynamicComponentContext: IDynamicComponentContext<Component, any>):
		Promise<IDynamicLayoutConfiguration>
	{
		if (AnyHelper.isNull(displayComponentInstance))
		{
			return null;
		}

		const componentDefinition: any =
			displayComponentInstance.displayComponentDefinition
				.jsonDefinition;

		return <IDynamicLayoutConfiguration>
			{
				data:
					AnyHelper.isNullOrWhitespace(
						componentDefinition.dataPromise)
						? dynamicComponentContext.data
						: await StringHelper.transformToDataPromise(
							componentDefinition.dataPromise,
							dynamicComponentContext),
				layout:
					FormlyHelper.getFormlyLayout(
						componentDefinition.layout,
						dynamicComponentContext),
				title:
					componentDefinition.title
			};
	}

	/**
	 * Creates and returns a component context that matches the required
	 * interface of stand alone dynamic component items. These component
	 * names are defined in the display component definition. If no component
	 * name is sent, the data promise value of the definition is returned.
	 *
	 * @async
	 * @param {DisplayComponentInstance} displayComponentInstance
	 * The display component instance to be instantiated using the generated
	 * context.
	 * @param {IDynamicComponentContext<Component, any>} dynamicComponentContext
	 * The dynamic component context for this dynamic component interface.
	 * @param {boolean} isViewOnly
	 * If sent as false and applicable, this will create an editable view. This
	 * value defaults to true.
	 * @returns {IDynamicComponentContext<Component, any>}
	 * An awaitable component context ready for an interfaced display.
	 * @memberof DisplayComponentFactory
	 */
	public async getDynamicComponentContext(
		displayComponentInstance: DisplayComponentInstance,
		dynamicComponentContext: IDynamicComponentContext<Component, any>,
		isViewOnly: boolean = true):
		Promise<IDynamicComponentContext<Component, any>>
	{
		const widgetComponentName: string =
			displayComponentInstance
				.displayComponentDefinition
				.componentName;
		const widgetDisplayComponentDefinition: any =
			displayComponentInstance
				.displayComponentDefinition
				.jsonDefinition;

		let widgetData: IDynamicComponentContext<Component, any>;
		switch (widgetComponentName)
		{
			case DynamicComponentLookup.supportedTypes.chartComponent:
				widgetData =
					<IDynamicComponentContext<Component, IChartContext<any>>>
					{
						source:
							dynamicComponentContext.source,
						data: {
							chartDefinition:
								await this.getChartDefinition(
									widgetDisplayComponentDefinition,
									dynamicComponentContext),
							data:
								widgetDisplayComponentDefinition
									.initialData,
							fillMissingDataSets:
								widgetDisplayComponentDefinition
									.fillMissingDataSets,
							maintainAspectRatio: false
						}
					};
				break;
			case DynamicComponentLookup.supportedTypes.dynamicWizardComponent:
				widgetData =
					<IDynamicComponentContext<Component, IWizardContext>>
					{
						source:
							dynamicComponentContext.source,
						data: await this.wizard(
							displayComponentInstance,
							dynamicComponentContext)
					};
				break;
			case DynamicComponentLookup.supportedTypes.externalReportComponent:
				const userHasEditRolePermissions: boolean =
					new User(this.sessionService.user)
						.hasMembership(
							PermissionConstants
								.editPowerBiReportRoles);
				const isStandardReport: boolean =
					displayComponentInstance.displayComponentDefinition
						.jsonDefinition.workspaceType ===
							ReportConstants.powerBiWorkspaceTypes.standard;
				widgetData =
					<IDynamicComponentContext<Component, any>>
					{
						source:
							dynamicComponentContext.source,
						data:
							{
								...dynamicComponentContext.data,
								reportDefinition:
									await this.externalReport(
										displayComponentInstance,
										dynamicComponentContext,
										isViewOnly === true
											|| userHasEditRolePermissions ===
												false
											|| isStandardReport ===
												true)
							}
					};
				break;
			case DynamicComponentLookup.supportedTypes
				.dynamicCommonTableComponent:
				widgetData =
					<IDynamicComponentContext<Component, ICommonTable>>
					{
						source:
							dynamicComponentContext.source,
						data: await this.simpleDataPromiseTable(
							displayComponentInstance,
							dynamicComponentContext)
					};
				break;
			case DynamicComponentLookup.supportedTypes
				.dynamicReallySimpleSyndicationFeedReaderComponent:
				widgetData =
					<IDynamicComponentContext<Component, any>>
					{
						source:
							dynamicComponentContext.source,
						data: await this.reallySimpleSyndicationFeed(
							displayComponentInstance)
					};
				break;
			case DynamicComponentLookup.supportedTypes
				.dynamicSummaryCardComponent:
				widgetData =
					<IDynamicComponentContext<
						Component,
						IInformationMenuItem<any>>>
					{
						source:
							dynamicComponentContext.source,
						data: await this.summaryCard(
							displayComponentInstance,
							dynamicComponentContext)
					};
				break;
			case DynamicComponentLookup.supportedTypes
				.dynamicWeatherCardComponent:
				widgetData =
					<IDynamicComponentContext<Component, any>>
					{
						source:
							dynamicComponentContext.source,
						data: {}
					};
				break;
			case DynamicComponentLookup.supportedTypes
				.dynamicFormlyWrapperComponent:
				widgetData =
					<IDynamicComponentContext<Component, any>>
					{
						source:
							dynamicComponentContext.source,
						data: await this.dynamicLayout(
							displayComponentInstance,
							dynamicComponentContext)
					};
				break;
			default:
				widgetData =
					<IDynamicComponentContext<Component, any>>
					{
						source: dynamicComponentContext.source,
						data: await StringHelper
							.transformToDataPromise(
								widgetDisplayComponentDefinition.dataPromise,
								dynamicComponentContext)
					};
				break;
		}

		return widgetData;
	}

	/**
	 * Creates and returns a wizard ready for display from the sent
	 * display component instance.
	 *
	 * @async
	 * @param {DisplayComponentInstance} displayComponentInstance
	 * The display component instance to be instantiated as a wizard.
	 * @param {IDynamicComponentContext<Component, any>} dynamicComponentContext
	 * The dynamic component context for this wizard.
	 * @returns {Promise<IWizardContext>}
	 * An awaitable wizard ready for display.
	 * @memberof DisplayComponentFactory
	 */
	public async wizard(
		displayComponentInstance: DisplayComponentInstance,
		dynamicComponentContext: IDynamicComponentContext<Component, any>):
		Promise<IWizardContext>
	{
		const jsonDefinition: IWizardContext =
			displayComponentInstance
				.displayComponentDefinition
				.jsonDefinition;

		dynamicComponentContext.data =
			await this.getMergedInitialParameterData(
				displayComponentInstance.jsonInitialParameters,
				dynamicComponentContext);

		const currentStepIndex: number =
			AnyHelper.isNullOrWhitespace(
				dynamicComponentContext.data.currentStepLabel)
				? 0
				: jsonDefinition.wizardSteps.findIndex(
					(wizardStep: any) =>
						wizardStep.stepName ===
							dynamicComponentContext.data.currentStepLabel);

		const decoratedWizardSteps: IWizardStep[] = [];
		let wizardMenuItems: IWizardStepMenuItem[] = [];
		for (let index: number = 0;
			index < jsonDefinition.wizardSteps.length;
			index++)
		{
			const currentStepDefinition: any =
				jsonDefinition.wizardSteps[index];

			const stepDisplayComponent: DisplayComponentInstance =
				await this.displayComponentService
					.populateDisplayComponentInstance(
						currentStepDefinition.displayComponent,
						dynamicComponentContext);
			const reloadVerify: boolean = currentStepIndex > index;

			// Require security and ownership permissions for all wizard steps.
			if (AnyHelper.isNull(stepDisplayComponent))
			{
				wizardMenuItems = [];

				break;
			}

			const step: any =
				stepDisplayComponent
					.displayComponentDefinition
					.jsonDefinition;

			const stepLayout: FormlyFieldConfig[] =
				this.getParameterLayoutSchema(
					step.stepDefinition,
					step.stepLayout,
					dynamicComponentContext);

			const currentWizardStepContext:
				IDynamicComponentContext<Component, any> =
					<IDynamicComponentContext<Component, any>>
					{
						source: dynamicComponentContext.source,
						data: {
							data: await this.getMergedInitialParameterData(
								stepDisplayComponent.jsonInitialParameters,
								dynamicComponentContext)
						}
					};
			wizardMenuItems.push(
				<IWizardStepMenuItem>
				{
					label: currentStepDefinition.stepName,
					currentData: currentWizardStepContext.data,
					lastContactData: currentWizardStepContext.data
				});
			const stepAlwaysVerify: any =
				AnyHelper.isNullOrWhitespace(
					step.alwaysVerify)
					? true
					: JSON.parse(step.alwaysVerify);
			const backLabelIndexZero: any =
				index === 0
					? null
					: 'Back';
			const hideNextButton: any =
				AnyHelper.isNullOrWhitespace(
					step.hideNextButton)
					? false
					: JSON.parse(step.hideNextButton);
			const defaultLabel: any =
				hideNextButton
					? null
					: 'Next';
			decoratedWizardSteps.push(
				<IWizardStep>
				{
					alwaysVerify:
						dynamicComponentContext.data.automateVerify === true
							|| reloadVerify === true
							? false
							: stepAlwaysVerify,
					backLabel:
						AnyHelper.isNullOrWhitespace(step.backLabel)
							? backLabelIndexZero
							: step.backLabel,
					displayComponent:
						AnyHelper.isNullOrWhitespace(step.displayComponent)
							? null
							: step.displayComponent,
					nextLabel:
						AnyHelper.isNullOrWhitespace(step.nextLabel)
							? defaultLabel
							: step.nextLabel,
					stepDefinition: step.stepDefinition,
					stepLayout: stepLayout,
					clientMessage:
						AnyHelper.isNullOrWhitespace(step.clientMessage)
							? null
							: step.clientMessage,

					// Store functions as strings for later interpolation.
					backActionRaw: step.backAction,
					stepDisplayRaw: step.stepDisplay,
					nextActionRaw: step.nextAction,
					validatorRaw: step.validator,
					allowedToContinueRaw:
						AnyHelper.isNullOrWhitespace(step.allowedToContinue)
							? null
							: step.allowedToContinue,
				});
		}

		return <IWizardContext>
		{
			displayedWizardMenuItems: wizardMenuItems,
			displayedWizardSteps: decoratedWizardSteps,
			label: jsonDefinition.label,
			wizardMenuItems: wizardMenuItems,
			wizardSteps: decoratedWizardSteps
		};
	}

	/**
	 * Initializes the wizard step functions to perform as expected when
	 * ran in the dynamic wizard component. The context source is the wizard
	 * page which holds a reference to the page source (The component that
	 * created the wizard).
	 *
	 * @param {IWizardStep[]} wizardSteps
	 * The wizard steps that require functions to run against the wizard
	 * component.
	 * @param {IDynamicComponentContext<Component, any>} dynamicComponentContext
	 * The dynamic component context for the wizard component.
	 * @memberof DisplayComponentFactory
	 */
	public initializeWizardStepFunctions(
		wizardSteps: IWizardStep[],
		dynamicComponentContext: IDynamicComponentContext<Component, any>): void
	{
		const emptyFunction: Function =
			() =>
			{
				// No implementation.
			};

		for (const wizardStep of wizardSteps)
		{
			wizardStep.backAction =
				AnyHelper.isNullOrWhitespace(wizardStep.backActionRaw)
					? emptyFunction
					: StringHelper.transformToFunction(
						wizardStep.backActionRaw,
						dynamicComponentContext);
			wizardStep.nextAction =
				AnyHelper.isNullOrWhitespace(wizardStep.nextActionRaw)
					? emptyFunction
					: StringHelper.transformToFunction(
						wizardStep.nextActionRaw,
						dynamicComponentContext);
			wizardStep.validator =
				AnyHelper.isNullOrWhitespace(wizardStep.validatorRaw)
					? null
					: StringHelper.transformToFunction(
						wizardStep.validatorRaw,
						dynamicComponentContext);
			wizardStep.allowedToContinue =
				AnyHelper.isNullOrWhitespace(
					wizardStep.allowedToContinueRaw)
					? null
					: StringHelper.transformToDataPromise(
						wizardStep.allowedToContinueRaw,
						dynamicComponentContext);
			wizardStep.stepDisplay =
				AnyHelper.isNullOrWhitespace(wizardStep.stepDisplayRaw)
					? () => true
					: StringHelper.transformToFunction(
						wizardStep.stepDisplayRaw,
						dynamicComponentContext);
		}
	}

	/**
	 * Performs a to function call on each value found in the initial
	 * parameter json object of a display component instance. This is
	 * used to initialize parameter values that cannot be set through
	 * defaults.
	 *
	 * @param {any} initialParameterJson
	 * The json data initial parameter object as parsed from the
	 * display component instance.
	 * @param {IDynamicComponentContext<Component, any>} pageContext
	 * The context of the current page displaying components.
	 * @param {any} secondaryParameterJson
	 * The json data secondary parameter object as parsed from the
	 * url route or other preset data objects.
	 * @returns {any}
	 * A parameter data value interpolated and created from the sent data.
	 * @memberof DisplayComponentFactory
	 */
	public async getMergedInitialParameterData(
		initialParameterJson: any,
		pageContext: IDynamicComponentContext<Component, any>,
		secondaryParameterJson: any = {}): Promise<any>
	{
		if (AnyHelper.isNull(initialParameterJson))
		{
			return {
				...pageContext.data,
				...secondaryParameterJson
			};
		}

		for (const propertyName of Object.keys(initialParameterJson))
		{
			if (AnyHelper.isNull(pageContext.data[propertyName]))
			{
				pageContext.data[propertyName] =
					await StringHelper.transformToDataPromise(
						initialParameterJson[propertyName],
						pageContext);
			}
		}

		return {
			...pageContext.data,
			...secondaryParameterJson
		};
	}

	/**
	 * Creates a common data object for use in display component
	 * parameters matching an entity instance or page context
	 * structure.
	 *
	 * @param {any} dataValue
	 * The json data value of the display component parameters.
	 * @returns {any}
	 * A parameter data value created from the sent data.
	 * @memberof DisplayComponentFactory
	 */
	public getParameterData(
		dataValue: any): any
	{
		return <any>
			{
				data: {...dataValue}
			};
	}

	/**
	 * Creates a parameter layout schema matching the sent json
	 * schema definition.
	 *
	 * @param {any} parameterDefinition
	 * The json definition parameters for this display component.
	 * @param {FormlyFieldConfig[]} parameterLayout
	 * The json parameter layout schema for this display component.
	 * @param {IDynamicComponentContext<Component, any>} dynamicComponentContext
	 * The dynamic component context for this parameter layout schema.
	 * @returns {any}
	 * A layout schema created from the parameter definition.
	 * @memberof DisplayComponentFactory
	 */
	public getParameterLayoutSchema(
		parameterDefinition: any,
		parameterLayout: FormlyFieldConfig[],
		dynamicComponentContext: IDynamicComponentContext<Component, any>):
		FormlyFieldConfig[]
	{
		if (AnyHelper.isNull(parameterLayout)
			|| parameterLayout.length === 0)
		{
			return AnyHelper.isNullOrWhitespace(
				parameterDefinition)
				? null
				: JSON.parse(new JsonSchemaLayout()
					.generateDefaultLayout(
						parameterDefinition,
						false));
		}

		return FormlyHelper.getFormlyLayout(
			parameterLayout,
			dynamicComponentContext);
	}

	/**
	 * Creates a chart definition from a json chart display instance.
	 *
	 * @async
	 * @param {any} chartDefinition
	 * The json definition definining a chart display instance.
	 * @param {IDynamicComponentContext<Component, any>} dynamicComponentContext
	 * The dynamic component context for this chart definition.
	 * @returns {Promise<IChartDefinition<any>>}
	 * An awaitable chart definition ready for display.
	 * @memberof DisplayComponentFactory
	 */
	private async getChartDefinition(
		chartDefinition: any,
		dynamicComponentContext: IDynamicComponentContext<Component, any>):
		Promise<IChartDefinition<any>>
	{
		return <IChartDefinition<any>>
			{
				chartColors: chartDefinition.colors,
				chartConfiguration: await StringHelper
					.transformToDataPromise(
						chartDefinition.chartConfiguration,
						dynamicComponentContext),
				chartPivotProperty: chartDefinition.pivotProperty,
				chartPivotProperties: chartDefinition.pivotProperties,
				chartRadialGaugeChart: chartDefinition.radialGaugeChart,
				chartSummaryFunction: chartDefinition.summaryFunction,
				dataPromise: StringHelper
					.transformToDataPromise(
						chartDefinition.dataPromise,
						dynamicComponentContext),
				groupByCount: chartDefinition.groupByCount
			};
	}

	/**
	 * Creates a dashboard widget from a populated dashboard widget
	 * display component instance.
	 *
	 * @async
	 * @param {string} dynamicComponentName
	 * The name of the dynamic component to display in this widget.
	 * @param {boolean} includeSecondaryDisplay
	 * If true this will also load and prepare a secondary display. The default
	 * value is true.
	 * @param {any} widgetDefinition
	 * The parsed json definition of the widget display instance.
	 * @param {FormlyFieldConfig[]} parameterLayoutSchema
	 * The json layout schema for widget parameters.
	 * @param {IDynamicComponentContext<Component, any>} pageContext
	 * The dynamic component context for the page displaying the widget.
	 * @param {DisplayComponentInstance} displayComponentInstance
	 * The display component instance for widget content.
	 * @param {DisplayComponentInstance} widgetDisplayComponentInstance
	 * The display component instance for the widget.
	 * @param {IDynamicComponentContext<Component, any>} widgetContext
	 * The dynamic component context for this dashboard widget.
	 * @returns {IDashboardWidget}
	 * A dashboard widget ready for display.
	 * @memberof DisplayComponentFactory
	 */
	private async getDashboardWidget(
		dynamicComponentName: string,
		includeSecondaryDisplay: boolean,
		widgetDefinition: any,
		parameterLayoutSchema: FormlyFieldConfig[],
		pageContext: IDynamicComponentContext<Component, any>,
		displayComponentInstance: DisplayComponentInstance,
		widgetDisplayComponentInstance: DisplayComponentInstance,
		widgetContext: IDynamicComponentContext<Component, any>):
		Promise<IDashboardWidget>
	{
		let secondaryWidget: IDashboardWidget;

		if (includeSecondaryDisplay === true
			&& !AnyHelper.isNullOrWhitespace(
				widgetDefinition.secondaryDisplayComponent))
		{
			const secondaryDisplayComponent: DisplayComponentInstance =
				await this.displayComponentService
					.populateDisplayComponentInstance(
						widgetDefinition.secondaryDisplayComponent,
						widgetContext);

			if (!AnyHelper.isNull(secondaryDisplayComponent))
			{
				secondaryWidget =
					await this.dashboardWidget(
						secondaryDisplayComponent,
						pageContext,
						false);
			}
		}

		return <IDashboardWidget>
			{
				displayComponentInstance: displayComponentInstance,
				dynamicComponent: dynamicComponentName,
				floatingIcon: AnyHelper.isNull(widgetDefinition.floatingIcon)
					? true
					: widgetDefinition.floatingIcon,
				forceSecondaryOverlay: widgetDefinition.forceSecondaryOverlay,
				removeBoxShadow: widgetDefinition.removeBoxShadow ?? false,
				height: widgetDefinition.height,
				parameterLayoutSchema: parameterLayoutSchema,
				parameterLayoutData:
					this.getParameterData(
						pageContext.data),
				widgetContext: widgetContext,
				widgetDisplayComponentInstance: widgetDisplayComponentInstance,
				width: widgetDefinition.width,
				secondaryDynamicComponent:
					secondaryWidget?.widgetDisplayComponentInstance
						?.displayComponentDefinition
						.parsedJson
						.definition
						.dynamicComponent
						?? secondaryWidget?.widgetDisplayComponentInstance
							?.displayComponentDefinition
							.componentName,
				secondaryDynamicContext: secondaryWidget?.widgetContext,
				secondaryHeight: secondaryWidget?.height,
				secondaryWidth: secondaryWidget?.width,
				secondaryTopAlign: secondaryWidget?.topAlign,
				secondaryFloatingIcon: AnyHelper.isNull(
					secondaryWidget?.floatingIcon)
					? true
					: secondaryWidget.floatingIcon,
				responsiveWidthMultiplier:
					widgetDefinition.responsiveWidthMultiplier ?? 1,
				topAlign: widgetDefinition.topAlign,
				useClickOnDisplay: widgetDefinition.useClickOnDisplay,
				flipCard: widgetDefinition.flipCard
			};
	}

	/**
	 * Loads and populates a chart display component from a chart display
	 * component name.
	 *
	 * @async
	 * @param {string} chartDisplayComponentName
	 * The chart display component name to load and populate.
	 * @param {IDynamicComponentContext<Component, any>} dynamicComponentContext
	 * The dynamic component context for this chart.
	 * @returns {Promise<IChartDefinition<any>>}
	 * An awaitable chart definition ready for display.
	 * @memberof DisplayComponentFactory
	 */
	private async populateChartDisplayComponent(
		chartDisplayComponentName: string,
		dynamicComponentContext: IDynamicComponentContext<Component, any>):
		Promise<IChartDefinition<any>>
	{
		const chartDisplayComponent: DisplayComponentInstance =
			await this.displayComponentService
				.populateDisplayComponentInstance(
					chartDisplayComponentName,
					dynamicComponentContext);

		if (AnyHelper.isNull(chartDisplayComponent))
		{
			return null;
		}

		return this.getChartDefinition(
			chartDisplayComponent.displayComponentDefinition.jsonDefinition,
			dynamicComponentContext);
	}
}