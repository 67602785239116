/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	CommonModule
} from '@angular/common';
import {
	NgModule
} from '@angular/core';
import {
	FormsModule,
	ReactiveFormsModule
} from '@angular/forms';
import {
	BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import {
	RouterModule
} from '@angular/router';
import {
	ApiModule
} from '@api/api.module';
import {
	AppContextMenuComponent
} from '@appComponents/app-context-menu/app-context-menu.component';
import {
	SlideMenuComponent
} from '@appComponents/app-context-menu/slide-menu/slide-menu.component';
import {
	LoginMessageService
} from '@appComponents/login/services/login-message.service';
import {
	PolicyDetailsComponent
} from '@claims/dynamic-components/policy-details/policy-details.component';
import {
	CloudChatComponent
} from '@dynamicComponents/cloud-chat/cloud-chat.component';
import {
	CommissionsListComponent
} from '@dynamicComponents/commissions/commissions-list/commissions-list.component';
import {
	CommissionsScheduleUpdateComponent
} from '@dynamicComponents/commissions/commissions-schedule-update/commissions-schedule-update.component';
import {
	CommissionsComponent
} from '@dynamicComponents/commissions/commissions.component';
import {
	EntityCreateComponent
} from '@dynamicComponents/drawer-entity-components/entity-create-component/entity-create.component';
import {
	EntityUpdateComponent
} from '@dynamicComponents/drawer-entity-components/entity-update-component/entity-update.component';
import {
	EntityViewComponent
} from '@dynamicComponents/drawer-entity-components/entity-view-component/entity-view.component';
import {
	DynamicCommonTableComponent
} from '@dynamicComponents/dynamic-common-table/dynamic-common-table.component';
import {
	DynamicFormlyWrapperComponent
} from '@dynamicComponents/dynamic-formly-wrapper/dynamic-formly-wrapper.component';
import {
	DynamicReallySimpleSyndicationFeedReaderComponent
} from '@dynamicComponents/dynamic-really-simple-syndication-feed-reader/dynamic-really-simple-syndication-feed-reader.component';
import {
	DynamicSummaryCardComponent
} from '@dynamicComponents/dynamic-summary-card/dynamic-summary-card.component';
import {
	DynamicWeatherCardComponent
} from '@dynamicComponents/dynamic-weather-card/dynamic-weather-card.component';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	ExternalReportComponent
} from '@dynamicComponents/external-report/external-report.component';
import {
	AddFileComponent
} from '@dynamicComponents/files/add-file/add-file.component';
import {
	DeleteFileComponent
} from '@dynamicComponents/files/delete-file/delete-file.component';
import {
	DownloadFileComponent
} from '@dynamicComponents/files/download-file/download-file.component';
import {
	EditFileComponent
} from '@dynamicComponents/files/edit-file/edit-file.component';
import {
	FileDetailsComponent
} from '@dynamicComponents/files/file-details/file-details.component';
import {
	FileProgressComponent
} from '@dynamicComponents/files/file-progress/file-progress.component';
import {
	FileComponent
} from '@dynamicComponents/files/file/file.component';
import {
	FilesComponent
} from '@dynamicComponents/files/files.component';
import {
	FinalWizardLayoutStepComponent
} from '@dynamicComponents/final-wizard-layout-step/final-wizard-layout-step.component';
import {
	HistoryTimelineComponent
} from '@dynamicComponents/history/history-timeline/history-timeline.component';
import {
	HistoryComponent
} from '@dynamicComponents/history/history.component';
import {
	NoteCreateComponent
} from '@dynamicComponents/notes/note-create/note-create.component';
import {
	NoteDeleteComponent
} from '@dynamicComponents/notes/note-delete/note-delete.component';
import {
	NoteListComponent
} from '@dynamicComponents/notes/note-list/note-list.component';
import {
	NoteViewComponent
} from '@dynamicComponents/notes/note-view/note-view.component';
import {
	NoteComponent
} from '@dynamicComponents/notes/note/note.component';
import {
	PaymentBatchListComponent
} from '@dynamicComponents/payment-batch/payment-batch-list/payment-batch-list.component';
import {
	PaymentBatchComponent
} from '@dynamicComponents/payment-batch/payment-batch.component';
import {
	ProductSettingActionComponent
} from '@dynamicComponents/product-settings/product-setting-action/product-setting-action.component';
import {
	ProductSettingListComponent
} from '@dynamicComponents/product-settings/product-setting-list/product-setting-list.component';
import {
	ProductSettingsComponent
} from '@dynamicComponents/product-settings/product-settings.component';
import {
	RuleListItemComponent
} from '@dynamicComponents/rules/rule-list-item/rule-list-item.component';
import {
	RuleListComponent
} from '@dynamicComponents/rules/rule-list/rule-list.component';
import {
	RuleOverrideDetailsComponent
} from '@dynamicComponents/rules/rule-override-details/rule-override-details.component';
import {
	RuleOverrideListComponent
} from '@dynamicComponents/rules/rule-override-list/rule-override-list.component';
import {
	RuleOverrideComponent
} from '@dynamicComponents/rules/rule-override/rule-override.component';
import {
	StonlyHelpComponent
} from '@dynamicComponents/stonly-help/stonly-help.component';
import {
	WorkItemListComponent
} from '@dynamicComponents/work-items/work-item-list/work-item-list.component';
import {
	WorkItemsComponent
} from '@dynamicComponents/work-items/work-items.component';
import {
	FieldValidationMessageComponent
} from '@entity/components/custom-components/field-validation-message/field-validation-message.component';
import {
	CustomRepeaterComponent
} from '@entity/components/custom-components/types/arrays/custom-repeater/custom-repeater.component';
import {
	CustomCalendarComponent
} from '@entity/components/custom-components/types/fields/custom-calendar/custom-calendar.component';
import {
	CustomCheckboxComponent
} from '@entity/components/custom-components/types/fields/custom-checkbox/custom-checkbox.component';
import {
	CustomDashboardComponent
} from '@entity/components/custom-components/types/fields/custom-dashboard/custom-dashboard.component';
import {
	CustomDataSelectComponent
} from '@entity/components/custom-components/types/fields/custom-data-select/custom-data-select.component';
import {
	CustomIconInputComponent
} from '@entity/components/custom-components/types/fields/custom-icon-input/custom-icon-input.component';
import {
	CustomIframeComponent
} from '@entity/components/custom-components/types/fields/custom-iframe/custom-iframe.component';
import {
	CustomImageInputComponent
} from '@entity/components/custom-components/types/fields/custom-image-input/custom-image-input.component';
import {
	CustomInputNumberComponent
} from '@entity/components/custom-components/types/fields/custom-input-number/custom-input-number.component';
import {
	CustomInputPasswordComponent
} from '@entity/components/custom-components/types/fields/custom-input-password/custom-input-password.component';
import {
	CustomInputRadioButtonComponent
} from '@entity/components/custom-components/types/fields/custom-input-radio-button/custom-input-radio-button.component';
import {
	CustomInputSearchComponent
} from '@entity/components/custom-components/types/fields/custom-input-search/custom-input-search.component';
import {
	CustomInputSwitchComponent
} from '@entity/components/custom-components/types/fields/custom-input-switch/custom-input-switch.component';
import {
	CustomInputComponent
} from '@entity/components/custom-components/types/fields/custom-input/custom-input.component';
import {
	CustomMaskedInputComponent
} from '@entity/components/custom-components/types/fields/custom-masked-input/custom-masked-input.component';
import {
	CustomMessageComponent
} from '@entity/components/custom-components/types/fields/custom-message/custom-message.component';
import {
	CustomMultiSelectComponent
} from '@entity/components/custom-components/types/fields/custom-multi-select/custom-multi-select.component';
import {
	CustomOperationButtonComponent
} from '@entity/components/custom-components/types/fields/custom-operation-button/custom-operation-button.component';
import {
	CustomResourceIdentifierComponent
} from '@entity/components/custom-components/types/fields/custom-resource-identifier/custom-resource-identifier.component';
import {
	CustomSectionTitleComponent
} from '@entity/components/custom-components/types/fields/custom-section-title/custom-section-title.component';
import {
	CustomSelectComponent
} from '@entity/components/custom-components/types/fields/custom-select/custom-select.component';
import {
	CustomSummaryCardComponent
} from '@entity/components/custom-components/types/fields/custom-summary-card/custom-summary-card.component';
import {
	CustomTableDisplayComponent
} from '@entity/components/custom-components/types/fields/custom-table-display/custom-table-display.component';
import {
	CustomInputTextAreaComponent
} from '@entity/components/custom-components/types/fields/custom-text-area/custom-text-area.component';
import {
	CustomTextDisplayComponent
} from '@entity/components/custom-components/types/fields/custom-text-display/custom-text-display.component';
import {
	CustomEmptyWrapperComponent
} from '@entity/components/custom-components/wrappers/custom-empty-wrapper/custom-empty-wrapper.component';
import {
	CustomFieldWrapperComponent
} from '@entity/components/custom-components/wrappers/custom-field-wrapper/custom-field-wrapper.component';
import {
	CustomTabContentComponent
} from '@entity/components/custom-components/wrappers/custom-tab-content/custom-tab-content.component';
import {
	EntitySelectComponent
} from '@entity/components/entity-select/entity-select.component';
import {
	applyDefaultOptions,
	emailValidator,
	emailValidatorMessage,
	invalidFormatValidationMessage,
	maximumLengthValidator,
	maximumNumberValidationMessage,
	maximumNumberValidator,
	maximumStringLengthValidationMessage,
	minimumLengthValidator,
	minimumNumberValidationMessage,
	minimumNumberValidator,
	minimumStringLengthValidationMessage,
	passwordValidator,
	passwordValidatorMessage,
	regularExpressionValidator
} from '@entity/entity-formly-definitions';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormlyModule
} from '@ngx-formly/core';
import {
	FormlyPrimeNGModule
} from '@ngx-formly/primeng';
import {
	OperationModule
} from '@operation/operation.module';
import {
	AngularCdkModule
} from '@shared/angular-cdk.module';
import {
	AccessDeniedContentComponent
} from '@shared/components/access-denied-content/access-denied-content.component';
import {
	BasePageTabMenuComponent
} from '@shared/components/base-page-tab-menu/base-page-tab-menu.component';
import {
	BasePageComponent
} from '@shared/components/base-page/base-page.component';
import {
	ColorSwatchComponent
} from '@shared/components/color-swatch/color-swatch.component';
import {
	CommonDashboardComponent
} from '@shared/components/common-dashboard/common-dashboard.component';
import {
	DashboardSectionComponent
} from '@shared/components/common-dashboard/dashboard-section/dashboard-section.component';
import {
	DashboardWidgetComponent
} from '@shared/components/common-dashboard/dashboard-section/dashboard-widget/dashboard-widget.component';
import {
	CommonIconTooltipComponent
} from '@shared/components/common-icon-tooltip/common-icon-tooltip.component';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	DifferenceDisplayComponent
} from '@shared/components/differences-display/difference-display/difference-display.component';
import {
	DifferencesDisplayComponent
} from '@shared/components/differences-display/differences-display.component';
import {
	DisplayComponentParameterHeaderComponent
} from '@shared/components/display-component-parameter-header/display-component-parameter-header.component';
import {
	DisplayComponentParameterComponent
} from '@shared/components/display-component-parameter/display-component-parameter.component';
import {
	DynamicFormlyComponent
} from '@shared/components/dynamic-formly/dynamic-formly.component';
import {
	DynamicComponent
} from '@shared/components/dynamic/dynamic.component';
import {
	InformationMenuComponent
} from '@shared/components/information-menu/information-menu.component';
import {
	QuickFiltersComponent
} from '@shared/components/quick-filters/quick-filters.component';
import {
	ReallySimpleSyndicationFeedReaderComponent
} from '@shared/components/really-simple-syndication-feed-reader/really-simple-syndication-feed-reader.component';
import {
	SearchFilterComponent
} from '@shared/components/search-filter/search-filter.component';
import {
	SummaryCardComponent
} from '@shared/components/summary-card/summary-card.component';
import {
	WeatherForecastComponent
} from '@shared/components/weather-forecast/weather-forecast.component';
import {
	BIReportSettingDirective
} from '@shared/directives/bi-report-setting.directive';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	MobileTooltipDirective
} from '@shared/directives/mobile-tooltip.directive';
import {
	PageContextDirective
} from '@shared/directives/page-context.directive';
import {
	ActivityListComponent
} from '@shared/dynamic-components/activity-list/activity-list.component';
import {
	ActivityComponent
} from '@shared/dynamic-components/activity-list/activity/activity/activity.component';
import {
	AddressListComponent
} from '@shared/dynamic-components/address-list/address-list.component';
import {
	ApplicationSearchComponent
} from '@shared/dynamic-components/application-search/application-search.component';
import {
	BIReportComponent
} from '@shared/dynamic-components/bi-report-settings/bi-report-settings.component';
import {
	ChartComponent
} from '@shared/dynamic-components/chart/chart.component';
import {
	CommonListComponent
} from '@shared/dynamic-components/common-list/common-list.component';
import {
	NotesComponent
} from '@shared/dynamic-components/notes/notes.component';
import {
	RulesComponent
} from '@shared/dynamic-components/rules/rules.component';
import {
	ChartFactory
} from '@shared/factories/chart-factory';
import {
	ArrayReversePipe
} from '@shared/pipes/array-reverse.pipe';
import {
	DateTimeToTrueLocalPipe
} from '@shared/pipes/date-time-to-true-local.pipe';
import {
	FormatYesNoPipe
} from '@shared/pipes/format-yes-no.pipe';
import {
	NumberToWordsPipe
} from '@shared/pipes/number-to-words.pipe';
import {
	PrimeNgModule
} from '@shared/primeng.module';
import {
	RuleTokenLookup
} from '@shared/rule-token.lookup';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	GenesysChatService
} from '@shared/services/genesys-chat.service';
import {
	LoggerService
} from '@shared/services/logger.service';
import {
	RulePresentationService
} from '@shared/services/rule-presentation.service';
import {
	RuleService
} from '@shared/services/rule.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SettingsService
} from '@shared/services/settings.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	UserService
} from '@shared/services/user.service';
import {
	SharedTokenLookup
} from '@shared/shared-token.lookup';
import {
	ChartTransform
} from '@shared/transforms/chart-transform';
import {
	EmailValidator
} from '@shared/validators/email.validator';
import {
	MaximumLengthValidator
} from '@shared/validators/maximum-length.validator';
import {
	MaximumNumberValidator
} from '@shared/validators/maximum-number.validator';
import {
	MinimumLengthValidator
} from '@shared/validators/minimum-length.validator';
import {
	MinimumNumberValidator
} from '@shared/validators/minimum-number.validator';
import {
	PasswordValidator
} from '@shared/validators/password.validator';
import {
	RegularExpressionValidator
} from '@shared/validators/regular-expression.validator';
import {
	RequiredValidator
} from '@shared/validators/required.validator';
import {
	LuxonModule
} from 'luxon-angular';
import {
	HighlightModule
} from 'ngx-highlightjs';
import {
	MarkdownModule
} from 'ngx-markdown';
import {
	MessageService
} from 'primeng/api';

/* eslint-enable max-len */

@NgModule({
	imports: [
		ApiModule,
		AngularCdkModule,
		BrowserAnimationsModule,
		CommonModule,
		FormsModule,
		HighlightModule,
		LuxonModule,
		MarkdownModule.forRoot(),
		OperationModule,
		PrimeNgModule,
		ReactiveFormsModule,
		RouterModule,
		FormlyPrimeNGModule,
		FormlyModule.forRoot({
			extensions: [
				{
					name: 'defaultOptions',
					extension: {
						prePopulate: applyDefaultOptions
					}
				}
			],
			extras: {
				lazyRender: false,
				resetFieldOnHide: false
			},
			types: [
				{
					name: 'custom-calendar',
					component: CustomCalendarComponent
				},
				{
					name: 'custom-checkbox',
					component: CustomCheckboxComponent
				},
				{
					name: 'custom-dashboard',
					component: CustomDashboardComponent
				},
				{
					name: 'custom-data-select',
					component: CustomDataSelectComponent
				},
				{
					name: 'custom-iframe',
					component: CustomIframeComponent
				},
				{
					name: 'custom-icon-input',
					component: CustomIconInputComponent
				},
				{
					name: 'custom-input-switch',
					component: CustomInputSwitchComponent
				},
				{
					name: 'custom-image-input',
					component: CustomImageInputComponent
				},
				{
					name: 'custom-input-radio-button',
					component: CustomInputRadioButtonComponent
				},
				{
					name: 'custom-input-search',
					component: CustomInputSearchComponent
				},
				{
					name: 'custom-input-number',
					component: CustomInputNumberComponent
				},
				{
					name: 'custom-input-password',
					component: CustomInputPasswordComponent
				},
				{
					name: 'custom-masked-input',
					component: CustomMaskedInputComponent
				},
				{
					name: 'custom-message',
					component: CustomMessageComponent
				},
				{
					name: 'custom-multi-select',
					component: CustomMultiSelectComponent
				},
				{
					name: 'custom-operation-button',
					component: CustomOperationButtonComponent
				},
				{
					name: 'custom-repeater',
					component: CustomRepeaterComponent
				},
				{
					name: 'custom-resource-identifier',
					component: CustomResourceIdentifierComponent
				},
				{
					name: 'custom-section-title',
					component: CustomSectionTitleComponent
				},
				{
					name: 'custom-select',
					component: CustomSelectComponent
				},
				{
					name: 'custom-summary-card',
					component: CustomSummaryCardComponent
				},
				{
					name: 'custom-table-display',
					component: CustomTableDisplayComponent
				},
				{
					name: 'custom-textarea',
					component: CustomInputTextAreaComponent
				},
				{
					name: 'custom-text-display',
					component: CustomTextDisplayComponent
				},
				{
					name: 'input',
					component: CustomInputComponent
				}
			],
			wrappers: [
				{
					name: 'custom-empty-wrapper',
					component: CustomEmptyWrapperComponent
				},
				{
					name: 'custom-field-wrapper',
					component: CustomFieldWrapperComponent
				},
				{
					name: 'custom-tab-content',
					component: CustomTabContentComponent
				}
			],
			validationMessages: [
				{
					name: 'required',
					message: 'This value is required.'
				},
				{
					name: 'minLength',
					message: minimumStringLengthValidationMessage
				},
				{
					name: 'minimumLength',
					message: minimumStringLengthValidationMessage
				},
				{
					name: 'maxLength',
					message: maximumStringLengthValidationMessage
				},
				{
					name: 'maximumLength',
					message: maximumStringLengthValidationMessage
				},
				{
					name: 'min',
					message: minimumNumberValidationMessage
				},
				{
					name: 'minimumNumber',
					message: minimumNumberValidationMessage
				},
				{
					name: 'max',
					message: maximumNumberValidationMessage
				},
				{
					name: 'maximumNumber',
					message: maximumNumberValidationMessage
				},
				{
					name: 'invalidPassword',
					message: passwordValidatorMessage
				},
				{
					name: 'pattern',
					message: invalidFormatValidationMessage
				},
				{
					name: 'regularExpression',
					message: invalidFormatValidationMessage
				},
				{
					name: 'email',
					message: emailValidatorMessage
				}
			],
			validators: [
				{
					name: 'email',
					validation: emailValidator
				},
				{
					name: 'maximumLength',
					validation: maximumLengthValidator
				},
				{
					name: 'minimumLength',
					validation: minimumLengthValidator
				},
				{
					name: 'maximumNumber',
					validation: maximumNumberValidator
				},
				{
					name: 'minimumNumber',
					validation: minimumNumberValidator
				},
				{
					name: 'password',
					validation: passwordValidator
				},
				{
					name: 'regularExpression',
					validation: regularExpressionValidator
				}
			]
		})
	],
	exports: [
		AccessDeniedContentComponent,
		CommonTablePageDirective,
		AngularCdkModule,
		ApiModule,
		ArrayReversePipe,
		BasePageComponent,
		BasePageTabMenuComponent,
		BrowserAnimationsModule,
		ColorSwatchComponent,
		CommonDashboardComponent,
		CommonIconTooltipComponent,
		CommonTableComponent,
		CustomCalendarComponent,
		CustomCheckboxComponent,
		CustomDashboardComponent,
		CustomDataSelectComponent,
		CustomTabContentComponent,
		CustomFieldWrapperComponent,
		CustomIconInputComponent,
		CustomInputComponent,
		CustomInputSwitchComponent,
		CustomIframeComponent,
		CustomImageInputComponent,
		CustomMaskedInputComponent,
		CustomMessageComponent,
		CustomMultiSelectComponent,
		CustomOperationButtonComponent,
		CustomRepeaterComponent,
		CustomResourceIdentifierComponent,
		CustomSectionTitleComponent,
		CustomSelectComponent,
		CustomInputRadioButtonComponent,
		CustomInputSearchComponent,
		CustomInputTextAreaComponent,
		CustomInputNumberComponent,
		CustomInputPasswordComponent,
		CustomRepeaterComponent,
		CustomMaskedInputComponent,
		CustomCheckboxComponent,
		CustomSummaryCardComponent,
		CustomTableDisplayComponent,
		CustomTextDisplayComponent,
		CommonListComponent,
		CommonModule,
		ChartComponent,
		DashboardSectionComponent,
		DashboardWidgetComponent,
		DifferenceDisplayComponent,
		DifferencesDisplayComponent,
		DateTimeToTrueLocalPipe,
		DisplayComponentParameterComponent,
		DisplayComponentParameterHeaderComponent,
		DynamicCommonTableComponent,
		DynamicReallySimpleSyndicationFeedReaderComponent,
		DynamicSummaryCardComponent,
		DynamicWeatherCardComponent,
		DynamicWizardComponent,
		DynamicComponent,
		DynamicFormlyComponent,
		DynamicFormlyWrapperComponent,
		EntitySelectComponent,
		ExternalReportComponent,
		FieldValidationMessageComponent,
		FinalWizardLayoutStepComponent,
		FormatYesNoPipe,
		FormsModule,
		FormlyModule,
		FormlyPrimeNGModule,
		MobileTooltipDirective,
		HighlightModule,
		InformationMenuComponent,
		LuxonModule,
		MarkdownModule,
		NumberToWordsPipe,
		OperationModule,
		PageContextDirective,
		PrimeNgModule,
		QuickFiltersComponent,
		ReactiveFormsModule,
		RouterModule,
		ReallySimpleSyndicationFeedReaderComponent,
		SearchFilterComponent,
		SlideMenuComponent,
		SummaryCardComponent,
		WeatherForecastComponent
	],
	providers: [
		UserService,
		{
			provide: SharedTokenLookup.tokens.UserService,
			useClass: UserService
		},
		ActivityListComponent,
		AddressListComponent,
		ApplicationSearchComponent,
		BIReportComponent,
		BIReportSettingDirective,
		ChartTransform,
		ChartComponent,
		CloudChatComponent,
		CommissionsComponent,
		CommissionsScheduleUpdateComponent,
		CommonDashboardComponent,
		CommonIconTooltipComponent,
		CommonTableComponent,
		DashboardSectionComponent,
		DashboardWidgetComponent,
		DisplayComponentParameterComponent,
		DisplayComponentParameterHeaderComponent,
		DynamicCommonTableComponent,
		DynamicFormlyWrapperComponent,
		DynamicReallySimpleSyndicationFeedReaderComponent,
		DynamicSummaryCardComponent,
		DynamicWeatherCardComponent,
		DynamicWizardComponent,
		EntitySelectComponent,
		ExternalReportComponent,
		FilesComponent,
		FinalWizardLayoutStepComponent,
		GenesysChatService,
		{
			provide: SharedTokenLookup.tokens.GenesysChatService,
			useClass: GenesysChatService
		},
		HistoryComponent,
		HistoryTimelineComponent,
		LoggerService,
		LoginMessageService,
		MessageService,
		NotesComponent,
		PaymentBatchComponent,
		PolicyDetailsComponent,
		ProductSettingsComponent,
		ProductSettingActionComponent,
		ProductSettingListComponent,
		RulesComponent,
		RuleListItemComponent,
		RulePresentationService,
		SiteLayoutService,
		SessionService,
		{
			provide: SharedTokenLookup.tokens.SessionService,
			useClass: SessionService
		},
		SettingsService,
		StonlyHelpComponent,
		WorkItemListComponent,
		WorkItemsComponent,
		CommissionsComponent,
		CommissionsListComponent,
		CommissionsScheduleUpdateComponent,
		EmailValidator,
		{
			provide: SharedTokenLookup.tokens.ActivityService,
			useClass: ActivityService
		},
		EmailValidator,
		{
			provide: RuleTokenLookup.tokens.Email,
			useClass: EmailValidator
		},
		ChartFactory,
		{
			provide: SharedTokenLookup.tokens.ChartFactory,
			useClass: ChartFactory
		},
		EntityService,
		{
			provide: SharedTokenLookup.tokens.EntityService,
			useClass: EntityService
		},
		MaximumLengthValidator,
		{
			provide: RuleTokenLookup.tokens.MaximumLength,
			useClass: MaximumLengthValidator
		},
		MinimumLengthValidator,
		{
			provide: RuleTokenLookup.tokens.MinimumLength,
			useClass: MinimumLengthValidator
		},
		MaximumNumberValidator,
		{
			provide: RuleTokenLookup.tokens.MaximumNumber,
			useClass: MaximumNumberValidator
		},
		MinimumNumberValidator,
		{
			provide: RuleTokenLookup.tokens.MinimumNumber,
			useClass: MinimumNumberValidator
		},
		PasswordValidator,
		{
			provide: RuleTokenLookup.tokens.Password,
			useClass: PasswordValidator
		},
		RegularExpressionValidator,
		{
			provide: RuleTokenLookup.tokens.RegularExpressionFormat,
			useClass: RegularExpressionValidator
		},
		RequiredValidator,
		{
			provide: RuleTokenLookup.tokens.Required,
			useClass: RequiredValidator
		},
		RuleService,
		{
			provide: SharedTokenLookup.tokens.RuleService,
			useClass: RuleService
		}
	],
	declarations: [
		AccessDeniedContentComponent,
		AddFileComponent,
		BIReportSettingDirective,
		CommonTablePageDirective,
		ActivityComponent,
		ActivityListComponent,
		AddressListComponent,
		AppContextMenuComponent,
		ApplicationSearchComponent,
		ArrayReversePipe,
		BasePageComponent,
		BasePageTabMenuComponent,
		BIReportComponent,
		ChartComponent,
		CloudChatComponent,
		ColorSwatchComponent,
		CommissionsComponent,
		CommissionsListComponent,
		CommissionsScheduleUpdateComponent,
		CommonListComponent,
		CommonDashboardComponent,
		CommonIconTooltipComponent,
		CommonTableComponent,
		CustomCalendarComponent,
		CustomCheckboxComponent,
		CustomDashboardComponent,
		CustomDataSelectComponent,
		CustomTabContentComponent,
		CustomFieldWrapperComponent,
		CustomIconInputComponent,
		CustomInputComponent,
		CustomInputSwitchComponent,
		CustomIframeComponent,
		CustomImageInputComponent,
		CustomMaskedInputComponent,
		CustomRepeaterComponent,
		CustomResourceIdentifierComponent,
		CustomInputRadioButtonComponent,
		CustomInputSearchComponent,
		CustomInputTextAreaComponent,
		CustomInputNumberComponent,
		CustomInputPasswordComponent,
		CustomMultiSelectComponent,
		CustomMessageComponent,
		CustomOperationButtonComponent,
		CustomRepeaterComponent,
		CustomSectionTitleComponent,
		CustomSelectComponent,
		CustomMaskedInputComponent,
		CustomCheckboxComponent,
		CustomSummaryCardComponent,
		CustomTableDisplayComponent,
		CustomTextDisplayComponent,
		DashboardSectionComponent,
		DashboardWidgetComponent,
		DeleteFileComponent,
		DifferenceDisplayComponent,
		DifferencesDisplayComponent,
		DateTimeToTrueLocalPipe,
		DisplayComponentParameterComponent,
		DisplayComponentParameterHeaderComponent,
		DynamicCommonTableComponent,
		DynamicFormlyWrapperComponent,
		DynamicReallySimpleSyndicationFeedReaderComponent,
		DynamicSummaryCardComponent,
		DynamicWeatherCardComponent,
		DynamicWizardComponent,
		DownloadFileComponent,
		DynamicComponent,
		DynamicFormlyComponent,
		EditFileComponent,
		EntityCreateComponent,
		EntitySelectComponent,
		EntityUpdateComponent,
		EntityViewComponent,
		ExternalReportComponent,
		FieldValidationMessageComponent,
		FileComponent,
		FilesComponent,
		FileDetailsComponent,
		FileProgressComponent,
		FinalWizardLayoutStepComponent,
		FormatYesNoPipe,
		MobileTooltipDirective,
		HistoryComponent,
		HistoryTimelineComponent,
		InformationMenuComponent,
		NumberToWordsPipe,
		NoteComponent,
		NotesComponent,
		NoteListComponent,
		NoteViewComponent,
		NoteCreateComponent,
		NoteDeleteComponent,
		ProductSettingsComponent,
		ProductSettingListComponent,
		ProductSettingActionComponent,
		PageContextDirective,
		QuickFiltersComponent,
		PaymentBatchComponent,
		PaymentBatchListComponent,
		PolicyDetailsComponent,
		ReallySimpleSyndicationFeedReaderComponent,
		RulesComponent,
		RuleListComponent,
		RuleOverrideListComponent,
		RuleListItemComponent,
		RuleOverrideComponent,
		RuleOverrideDetailsComponent,
		SearchFilterComponent,
		SlideMenuComponent,
		StonlyHelpComponent,
		SummaryCardComponent,
		WeatherForecastComponent,
		WorkItemListComponent,
		WorkItemsComponent
	]
})

/**
 * A module to be shared across the application used
 * to hold common shared modules that we want to hand across
 * the application.
 *
 * @export
 * @class SharedModule
 */
export class SharedModule
{
}