<ng-template
	[ngIf]="loading === true"
	[ngIfElse]="Content">
	<div class="ui-g-12 no-horizontal-padding text-center">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</div>
</ng-template>

<ng-template #Content>
	<div class="ui-g-12 create-button-set no-padding">
		<button
			*ngIf="hasClaimsMembership"
			pButton
			label="Create Claim"
			(click)="createNavigation(createClaimOperationDefinitionName)">
		</button>
		<button
			pButton
			*ngIf="hasPolicyMembership"
			label="Create Quote"
			class="create-quote-button"
			[ngClass]="{
				'left-margin-button': hasClaimsMembership
			}"
			(click)="createNavigation(createQuoteOperationDefinitionName)">
		</button>
	</div>
</ng-template>