<form [formGroup]="verifyForm" (ngSubmit)="verify()">
	<div class="ui-g">
		<div class="ui-g-3 icon-login"
			style="text-align:left;">
			<img src= "../assets/layout/images/login/icon-login.svg"
				alt="Login Icon">
		</div>
		<div class="ui-g-9" style="text-align:right;">
			<h2 class="welcome-text">
				Welcome {{displayName()}}
			</h2>
			<span class="guest-sign-in">
				Verify your session
			</span>
		</div>
		<div class="ui-g-12" style="text-align:left;">
			An authentication code has been sent to your
			{{verifyMethod()}} ({{maskedVerifyDestination()}}). Use the code you recieve
			to verify your login.
		</div>
		<div class="ui-g-12" style="text-align:left;">
			<label class="login-label"
				for="code">
				Code
			</label>
			<div class="login-input">
				<input
					pInputText
					type="text"
					#code
					id="code"
					formControlName="code"
					minlength="1"
					required="required"
					[@shakeAnimation]="verifyForm.invalid" />
			</div>
		</div>
		<div class="ui-g-12 ui-md-6 button-pane">
			<button
				pButton
				type="submit"
				label="Verify"
				aria-disabled="false"
				class="p-button-primary"
				[disabled]="verifyIsDisabled">
				<div
					class="loading-indicator"
					*ngIf="this.submitInProgress">
					<p-progressSpinner
						strokeWidth="5">
					</p-progressSpinner>
				</div>
			</button>
		</div>
		<div class="ui-g-12 ui-md-6 button-pane">
			<button
				pButton
				type="button"
				label="Cancel"
				class="p-button-secondary"
				aria-disabled="false"
				(click)="cancel()">
			</button>
		</div>
	</div>
</form>