<ng-template
	[ngIf]="loading === true"
	[ngIfElse]="Content">
	<span class="center-spinner loading-spinner theme-color fa fa-fw fa-spin fa-spinner"
		*ngIf="loading">
	</span>
</ng-template>
<ng-template #Content>
	<form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()" *ngIf="loading === false">
		<div class="ui-g">
			<div class="ui-g-3 icon-login"
				style="text-align:left;">
				<img src="../assets/layout/images/login/icon-login.svg"
					alt="Login Icon">
			</div>
			<div class="ui-g-9" style="text-align:right;">
				<h2 class="welcome-text">
					Welcome ({{this.userName()}})
				</h2>
				<span class="guest-sign-in">
					Change Password
				</span>
			</div>
			<div class="ui-g-12" style="text-align:left;">
				<label for="newPassword"
					class="login-label">
					New Password
				</label>
				<app-common-icon-tooltip
					content="<div>Your new password must contain at least:<ul><li>At least {{getSecurityRestrictions()?.lowercase}} lowercase</li><li>At least {{getSecurityRestrictions()?.uppercase}} uppercase</li><li>At least {{getSecurityRestrictions()?.number}} numeric</li><li>At least{{getSecurityRestrictions()?.special}} special <strong>{{getPasswordSpecialCharacters()}}</strong></li><li>Minimum {{getSecurityRestrictions()?.minimumLength}} characters</li><li>Maximum {{getSecurityRestrictions()?.maximumLength}} characters</li><li>Allowed Password Reuse: {{getSecurityRestrictions()?.allowPasswordReuse}}</li></ul></div>"
					[escape]="false">
				</app-common-icon-tooltip>
				<div class="login-input">
					<input
						pInputText
						type="password"
						#newPassword
						id="newPassword"
						formControlName="newPassword" />
					<div
						class="theme-color-red"
						*ngIf="this.hasInvalidPassword()">
						Password is invalid.
					</div>
				</div>
			</div>
			<div class="ui-g-12" style="text-align:left;">
				<label for="confirmPassword"
					class="login-label">
					Re-enter Password
				</label>
				<div class="login-input">
					<input
						pInputText
						type="password"
						#confirmPassword
						id="confirmPassword"
						formControlName="confirmPassword" />
				</div>
			</div>
			<div class="ui-g-12 ui-md-6 button-pane">
				<button
					pButton
					type="submit"
					label="Confirm"
					aria-disabled="false"
					class="p-button-primary"
					[disabled]="this.confirmIsDisabled()">
					<div class="loading-indicator"
						*ngIf="this.submitInProgress">
						<p-progressSpinner strokeWidth="5">
						</p-progressSpinner>
					</div>
				</button>
			</div>
			<div class="ui-g-12 ui-md-6 button-pane">
				<button
					pButton
					type="button"
					label="Cancel"
					aria-disabled="false"
					class="p-button-secondary"
					(click)="cancel()">
				</button>
			</div>
		</div>
	</form>
</ng-template>