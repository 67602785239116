<form [formGroup]="resetForm" (ngSubmit)="reset()">
	<div class="ui-g">
		<div class="ui-g-3 icon-login"
			style="text-align:left;">
			<img src="../assets/layout/images/login/icon-login.svg"
				alt="Login Icon">
		</div>
		<div class="ui-g-9" style="text-align:right;">
			<h2 class="welcome-text">
				Welcome Guest
			</h2>
			<span class="guest-sign-in">
				Reset your account
			</span>
		</div>
		<div class="ui-g-12" style="text-align:left;">
			Upon resetting your account, a new temporary password will be sent to
			your registered e-mail.
		</div>
		<div class="ui-g-12" style="text-align:left;">
			<label for="userName"
				class="login-label">
				Username
			</label>
			<div class="login-input">
				<input
					pInputText
					type="text"
					#userName
					id="userName"
					formControlName="userName"
					minlength="3"
					required="required"
					[@shakeAnimation]="resetForm.invalid" />
			</div>
		</div>
		<div class="ui-g-12 ui-md-6 button-pane">
			<button
				pButton
				type="submit"
				label="Reset"
				aria-disabled="false"
				class="p-button-primary"
				[disabled]="resetIsDisabled">
				<div
					class="loading-indicator"
					*ngIf="this.submitInProgress">
					<p-progressSpinner
						strokeWidth="5">
					</p-progressSpinner>
				</div>
			</button>
		</div>
		<div class="ui-g-12 ui-md-6 button-pane">
			<button
				pButton
				type="button"
				label="Cancel"
				aria-disabled="false"
				class="p-button-secondary"
				(click)="cancel()">
			</button>
		</div>
	</div>
</form>