/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	User
} from '@shared/implementations/users/user';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-policy-and-claim-quick-search',
	templateUrl: './policy-and-claim-quick-search.component.html',
	styleUrls: [
		'./policy-and-claim-quick-search.component.scss']
})

/**
 * A component representing a simple display of a policy and claim
 * quick search layout.
 *
 * @export
 * @class PolicyAndClaimQuickSearchComponent
 * @implements {IDynamicComponent<Component, any>}
 * @implements {OnInit}
 */
export class PolicyAndClaimQuickSearchComponent
implements IDynamicComponent<Component, any>, OnInit
{
	/**
	 * Initializes a new instance of the policy and claim quick search
	 * component.
	 *
	 * @param {Router} router
	 * The operation service to use when loading operation group data.
	 * @param {SessionService} sessionService
	 * The session service used to load user data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to load navigation data.
	 * @memberof PolicyAndClaimQuickSearchComponent
	 */
	public constructor(
		public router: Router,
		public sessionService: SessionService,
		public entityInstanceApiService: EntityInstanceApiService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof PolicyAndClaimQuickSearchComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the value signifying whether or not this component is
	 * loading.
	 *
	 * @type {boolean}
	 * @memberof PolicyAndClaimQuickSearchComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the value signifying whether or not the go to functionality
	 * is disabled.
	 *
	 * @type {boolean}
	 * @memberof PolicyAndClaimQuickSearchComponent
	 */
	public disabled: boolean = true;

	/**
	 * Gets or sets the search text used in the input.
	 *
	 * @type {string}
	 * @memberof PolicyAndClaimQuickSearchComponent
	 */
	public searchText: string = AppConstants.empty;

	/**
	 * Gets or sets the selected type used in the dropdown.
	 *
	 * @type {string}
	 * @memberof PolicyAndClaimQuickSearchComponent
	 */
	public selectedType: string;

	/**
	 * Gets or sets the search placeholder based on the selected category.
	 *
	 * @type {string}
	 * @memberof PolicyAndClaimQuickSearchComponent
	 */
	public searchPlaceholder: string = 'Policy Number';

	/**
	 * Gets or sets the search types.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof PolicyAndClaimQuickSearchComponent
	 */
	public readonly searchTypes: IDropdownOption[] = <IDropdownOption[]>[];

	/**
	 * Implements the OnInit interface.
	 * This will set the initial values for the component.
	 *
	 * @memberof PolicyAndClaimQuickSearchComponent
	 */
	public ngOnInit(): void
	{
		const user: User =
			new User(this.sessionService.user);

		const hasClaimsMembership: boolean =
			user.hasMembership(
				[
					AppConstants.securityGroups.claimModuleAccess
				]);
		const hasPolicyMembership: boolean =
			user.hasMembership(
				[
					AppConstants.securityGroups.policyModuleAccess
				]);

		if (hasPolicyMembership === true)
		{
			this.searchTypes.push(
				{
					label: InsuranceConstants.insuranceEntityTypes.policy,
					value: InsuranceConstants.insuranceEntityTypes.policy
				});
		}

		if (hasClaimsMembership === true)
		{
			this.searchTypes.push(
				{
					label: ClaimConstants.claimEntityTypes.claim,
					value: ClaimConstants.claimEntityTypes.claim,
				});
		}

		this.selectedType =
			hasPolicyMembership === true
				? InsuranceConstants.insuranceEntityTypes.policy
				: ClaimConstants.claimEntityTypes.claim;

		this.updateControls();
		this.loading = false;
	}

	/**
	 * Handles search type and filter data to customize the view and
	 * enable the navigate action when valid.
	 *
	 * @memberof PolicyAndClaimQuickSearchComponent
	 */
	public updateControls(): void
	{
		const typeSwitch: boolean =
			this.searchPlaceholder.indexOf(this.selectedType) === -1;

		if (typeSwitch === true)
		{
			this.searchPlaceholder =
				`${this.selectedType} Number`;
			this.searchText = AppConstants.empty;
		}

		this.disabled =
			AnyHelper.isNullOrWhitespace(this.searchText)
				|| AnyHelper.isNullOrWhitespace(this.selectedType);
	}

	/**
	 * Navigates to the selected policy or claim based on the input type
	 * and number.
	 *
	 * @async
	 * @memberof PolicyAndClaimQuickSearchComponent
	 */
	public async navigateToEntity(): Promise<void>
	{
		if (this.disabled === true)
		{
			return;
		}

		const searchText: string =
			this.searchText.trim();
		const policyNavigation: boolean =
			this.selectedType ===
				InsuranceConstants.insuranceEntityTypes.policy;
		const entityTypeGroup: string =
			policyNavigation === true
				? InsuranceConstants.insuranceEntityTypeGroups.policyTerms
				: ClaimConstants.claimEntityTypeGroups.claims;
		const camelcaseEntityType: string =
			StringHelper.toCamelCase(
				this.selectedType);
		const filter: string =
			policyNavigation === true
				? `${InsuranceConstants.commonProperties.policyNumber} eq `
					+ `'${searchText}'`
				: `${ClaimConstants.commonProperties.claimNumber} eq `
					+ `'${searchText}'`;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			entityTypeGroup;
		const entity: IEntityInstance =
			await this.entityInstanceApiService.getSingleQueryResult(
				filter,
				AppConstants.commonProperties.id
					+ AppConstants.characters.space
					+ AppConstants.sortDirections.descending,
				true);

		if (AnyHelper.isNull(entity))
		{
			EventHelper.dispatchBannerEvent(
				`${this.selectedType} Not Found`,
				`No ${camelcaseEntityType} found with `
					+ `the provided ${camelcaseEntityType} number.`,
				AppConstants.activityStatus.error);

			return;
		}

		this.router.navigate(
			[
				`${camelcaseEntityType}/${AppConstants.route.entities}`,
				entityTypeGroup,
				AppConstants.viewTypes.edit,
				entity.id
			],
			{
				queryParams: {
					routeData: ObjectHelper.mapRouteData(
						{
							layoutType: AppConstants.layoutTypes.full
						})
				}
			});
	}
}