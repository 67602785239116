/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	HostListener,
	Input,
	OnInit
} from '@angular/core';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IChannelFeed
} from '@shared/interfaces/really-simple-syndication/channel-feed.interface';
import {
	IItemFeed
} from '@shared/interfaces/really-simple-syndication/item-feed.interface';
import {
	ReallySimpleSyndicationFeedReaderService
} from '@shared/services/really-simple-syndication-feed-reader.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

@Component({
	selector: 'app-really-simple-syndication-feed-reader',
	templateUrl: './really-simple-syndication-feed-reader.component.html',
	styleUrls: [
		'./really-simple-syndication-feed-reader.component.scss'
	]
})

/**
 * A component representing an instance of the
 * really simple syndication feed reader component.
 *
 * @export
 * @class ReallySimpleSyndicationFeedReaderComponent
 * @implements {OnInit}
 * The data type returned in the data promise.
 */
export class ReallySimpleSyndicationFeedReaderComponent
implements OnInit
{
	/** Initializes a new instance of the ReallySimpleSyndicationFeedReaderComponent.
	 *
	 * @param {ReallySimpleSyndicationFeedReaderService}
	 * ReallySimpleSyndicationFeedReaderService
	 * The reader service to get really simple syndication feed data
	 * from an standardized xml feed.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service to handle responsive views.
	 * @memberof ReallySimpleSyndicationFeedReaderComponent
	*/
	public constructor(
		public reallySimpleSyndicationFeedReaderService:
			ReallySimpleSyndicationFeedReaderService,
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets the feed Source url.
	 *
	 * @type {string}
	 * @memberof ReallySimpleSyndicationFeedReaderComponent
	*/
	@Input() public feedSourceUrl: string;

	/**
	 * Gets or sets the feed content.
	 *
	 * @type {IChannelFeed}
	 * @memberof ReallySimpleSyndicationFeedReaderComponent
	*/
	public feedContent: IChannelFeed;

	/**
	 * Gets or sets the feed item groups.
	 *
	 * @type {IItemFeed[]}
	 * @memberof ReallySimpleSyndicationFeedReaderComponent
	*/
	public feedItemGroups: IItemFeed[];

	/**
	 * Gets or sets the loading truthy of this component.
	 *
	 * @type {boolean}
	 * @memberof ReallySimpleSyndicationFeedReaderComponent
	 */
	public loading: boolean = true;

	/**
	 * Sets the feed items chunk size.
	 *
	 * @type {{
		large: number;
		medium: number;
		small: number;
	}}
	 * @memberof ReallySimpleSyndicationFeedReaderComponent
	*/
	private readonly feedItemsChunkSize: {
		large: number;
		medium: number;
		small: number;
	} = {
			large: 8,
			medium: 4,
			small: 2,
		};

	/**
	 * Handles the site layout changes.
	 * This will set the feed item groups
	 * based on the available sceen width breakpoints.
	 *
	 * @memberof WeatherForecastComponent
	*/
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		if (this.loading === true)
		{
			return;
		}

		this.loading = true;
		this.setFeedItemGroups();
	}

	/**
	 * Handles the on initilazation event for this component.
	 * This will retrieve the standardized rss feed data and
	 * split the data into chunks for the carousel type of view.
	 *
	 * @async
	 * @memberof ReallySimpleSyndicationFeedReaderComponent
	*/
	public async ngOnInit(): Promise<void>
	{
		this.feedContent =
			await this.reallySimpleSyndicationFeedReaderService
				.readFeedContent(this.feedSourceUrl);
		this.setFeedImage();
		this.setFeedItemGroups();
	}

	/**
	 * Handles the card click event to open the feed item url
	 * in a new browser tab.
	 *
	 * @param {IItemFeed} feedItem
	 * The feed item clicked.
	 * @memberof ReallySimpleSyndicationFeedReaderComponent
	*/
	public cardClicked(feedItem: IItemFeed): void
	{
		window.open(
			feedItem.link,
			AppConstants.windowTargets.blank);
	}

	/**
	 * Sets the feed item groups to be ready to displayed
	 * into the carousel cards.
	 *
	 * @memberof ReallySimpleSyndicationFeedReaderComponent
	*/
	private setFeedItemGroups(): void
	{
		const itemGroupChunkSize: number =
			this.getItemGroupChunkSize();

		this.feedItemGroups = [];

		for (let index = 0;
			index < this.feedContent.items.length;
			index += itemGroupChunkSize)
		{
			const feedItemGroup: any =
				this.feedContent.items
					.slice(
						index,
						index + itemGroupChunkSize);

			this.feedItemGroups.push(feedItemGroup);
		}

		this.loading = false;
	}

	/**
	 * Sets the feed image.
	 *
	 * @memberof ReallySimpleSyndicationFeedReaderComponent
	*/
	private setFeedImage(): void
	{
		this.feedContent.image.url =
			(!AnyHelper.isNullOrEmpty(this.feedContent.image.url))
				? this.feedContent.image.url
				: null;
	}

	/**
	 * Gets the item group chunk size.
	 *
	 * @returns {number}
	 * The chunk size number of items that will be contained
	 * on each carrousel view.
	 * @memberof ReallySimpleSyndicationFeedReaderComponent
	*/
	private getItemGroupChunkSize(): number
	{
		if (this.getExtraLargeScreen() === true)
		{
			return this.feedItemsChunkSize.large;
		}

		return this.getLargeScreen()
			? this.feedItemsChunkSize.medium
			: this.feedItemsChunkSize.small;
	}

	/**
	 * Gets the large screen truthy.
	 *
	 * @returns {boolean}
	 * truthy if the screen is defined as large based on the
	 * available width breakpoint.
	 * @memberof ReallySimpleSyndicationFeedReaderComponent
	*/
	private getLargeScreen(): boolean
	{
		return this.siteLayoutService.contentWidth
			> AppConstants.layoutBreakpoints.phone;
	}

	/**
	 * Gets the extra large screen truthy.
	 *
	 * @returns {boolean}
	 * truthy if the screen is defined as large based on the
	 * available width breakpoint.
	 * @memberof ReallySimpleSyndicationFeedReaderComponent
	*/
	private getExtraLargeScreen(): boolean
	{
		return this.siteLayoutService.contentWidth
			> AppConstants.layoutBreakpoints.largeDesktop;
	}
}