/* !
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	CommissionsHelper
} from '@insurance/helpers/commissions..helper';
import {
	CommonDashboardComponent
} from '@shared/components/common-dashboard/common-dashboard.component';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import { DateHelper } from '@shared/helpers/date.helper';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	cloneDeep
} from 'lodash-es';

/* eslint-enable max-len */

@Component({
	selector: 'commission-disbursement-details',
	templateUrl: './commission-disbursement-details.component.html'
})

/**
 * A component representing a commissions disbursment details component.
 *
 * @export
 * @class CommissionDisbursementDetailsComponent
 * @extends CommonTablePageDirective
 * @implements {IDynamicComponent<Component, any>}
 * @implements {OnInit}
 */
export class CommissionDisbursementDetailsComponent
	extends CommonTablePageDirective
	implements OnInit, IDynamicComponent<CommonDashboardComponent, any>
{
	/**
	 * Initializes an instance of the commission disbursement details component.
	 *
	 * @param {ResolverService} resolver
	 * The resolver service used in this component.
	 * @memberof CommissionDisbursementDetailsComponent
	 */
	public constructor(
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonDashboardComponent, any>}
	 * @memberof CommissionDisbursementDetailsComponent
	 */
	public context: IDynamicComponentContext<CommonDashboardComponent, any>;

	/**
	 * Gets or sets the common table.
	 *
	 * @type {ICommonTable}
	 */
	public commonTable: ICommonTable;

	/**
	 * Gets or sets the selected table filter.
	 *
	 * @type {string}
	 * @memberof CommissionDisbursementDetailsComponent
	 */
	public tableFilter: string = 'All';

	/**
	 * Gets or sets the list of commission disbursements.
	 *
	 * @type {any[]}
	 * @memberof CommissionDisbursementDetailsComponent
	 */
	public disbursements: any[] = [];

	/**
	 * Gets or sets the list of filtered disbursements.
	 *
	 * @type {any[]}
	 * @memberof CommissionDisbursementDetailsComponent
	 */
	public filteredDisbursements: any[] = [];

	/**
	 * Gets or sets the selected row count..
	 *
	 * @type {number}
	 * @memberof CommissionDisbursementDetailsComponent
	 */
	public selectedRowCount: number = 5;

	/**
	 * Gets or sets the selected agency.
	 *
	 * @type {any}
	 * @memberof CommissionDisbursementDetailsComponent
	 */
	public selectedAgency: any;

	/**
	 * Gets the set of quick filters for this component.
	 *
	 * @type {object}
	 * @memberof CommissionDisbursementDetailsComponent
	 */
	public readonly quickfilters:
		{
			all: string;
			positiveAmount: string;
			negativeAmount: string;
			zeroAmount: string;
			commissionsAmount: string;
			adjustmentsAmount: string;
			directCommissionsAmount: string;
			subCommissionsAmount: string;
		} = {
			all: 'All',
			positiveAmount: 'Positive',
			negativeAmount: 'Negative',
			zeroAmount: 'Zero',
			commissionsAmount: 'Commissions',
			adjustmentsAmount: 'Adjustments',
			directCommissionsAmount: 'Direct Commissions',
			subCommissionsAmount: 'Sub Commissions'
		};

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof CommissionDisbursementDetailsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.context.data =
			this.context.source.entityContext.data.entityInstance;

		this.disbursements =
			this.getCommissionDisbursementDetails(
				this.context.data.data.disbursements);

		this.setupTableDefinitions();
		this.setfilteredDisbursements();

		this.loadingTableDefinitions = false;
	}

	/**
	 * Gets the commission disbursement details.
	 *
	 * @async
	 * @memberof CommissionDisbursementDetailsComponent
	 */
	public getCommissionDisbursementDetails(
		disbursements: any): any
	{
		const disbursementsClone: any =
			cloneDeep(disbursements);

		const disbursementDetails =	disbursementsClone
			.flatMap(
				(disbursement: any) =>
					disbursement.commissions.map(
						(commission: any) =>
						{
							const transaction: any =
								commission.ledgerTransactionCommission;
							const metadata: any =
								transaction.metadata;

							const disbursementDetail: any =
								{
									organizationId:
										disbursement.organizationId,
									agencyName:
										!AnyHelper.isNullOrWhitespace(
											metadata.agencyName)
											? metadata.agencyName
											: disbursement
												.organizationName
												.displayName,
									date:
										!AnyHelper.isNullOrWhitespace(
											commission.createDate)
											? commission.createDate
											: commission.effectiveDate,
									policyInstanceId:
										metadata.policyInstanceId,
									policyNumber:
										metadata.policyNumber,
									policyTermTransactionEntityType:
										metadata
											.policyTermTransactionEntityType,
									policyTermTransactionInstanceId:
										metadata
											.policyTermTransactionInstanceId,
									transactionNumber:
										metadata.transactionNumber,
									adjustmentType:
										transaction.adjustmentType,
									note:
										transaction.note,
									commissionableAmount:
										metadata.commissionableAmount,
									amount:
										transaction.amount,
									isCommissionType:
										CommissionsHelper
											.isCommissionType(
												transaction),
									isAdjustmentType:
										CommissionsHelper
											.isAdjustmentType(
												transaction),
									isDirectCommissionType:
										CommissionsHelper
											.isDirectCommissionType(
												transaction),
									isSubCommissionType:
										CommissionsHelper
											.isSubCommissionType(
												transaction),
								};

							return disbursementDetail;
						}));

		// Sort details by agencyName (asc) and date (desc)
		disbursementDetails
			.sort(
				(detailOne: any, detailTwo: any) =>
				{
					const agencyComparison =
						detailOne
							.agencyName
							.localeCompare(detailTwo.agencyName);

					if (agencyComparison !== 0)
					{
						return agencyComparison;
					}

					// Compares date in desc mode
					const dateOne = DateHelper.fromUtcIso(detailOne.date);
					const dateTwo = DateHelper.fromUtcIso(detailTwo.date);

					return dateTwo.toMillis() - dateOne.toMillis();
				});

		return disbursementDetails;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @memberof CommissionDisbursementDetailsComponent
	 */
	public setupTableDefinitions(): void
	{
		const policyNumberLinkFunction: string =
			'return function() { return { url: '
				+ '\'policy/entities/policies/edit/\''
				+ ' + this.policyInstanceId, '
				+ 'layoutType: \'Full\' }; }';

		const transactionNumberLinkFunction: string =
			'return function() { return { url: '
				+ '\'policy/entities/\''
				+ ' + this.policyTermTransactionEntityType + \''
				+ '/edit/\''
				+ ' + this.policyTermTransactionInstanceId, '
				+ 'layoutType: \'Full\' }; }';

		this.availableColumns =
			<ICommonTableColumn[]>
			[
				{
					dataKey: 'agencyName',
					columnHeader: 'Agency',
					displayOrder: 1
				},
				{
					dataKey: 'date',
					columnHeader: 'Date',
					dataFormatType: AppConstants.dataFormatTypes.date,
					displayOrder: 2
				},
				{
					dataKey: 'policyNumber',
					columnHeader: 'Policy #',
					dataFormatType: AppConstants.dataFormatTypes.link,
					linkFunction: policyNumberLinkFunction,
					displayOrder: 3
				},
				{
					dataKey: 'transactionNumber',
					columnHeader: 'Transaction #',
					dataFormatType: AppConstants.dataFormatTypes.link,
					linkFunction: transactionNumberLinkFunction,
					displayOrder: 4
				},
				{
					dataKey: 'adjustmentType',
					columnHeader: 'Adjustment Type',
					displayOrder: 5
				},
				{
					dataKey: 'note',
					columnHeader: 'Note',
					displayOrder: 6
				},
				{
					dataKey: 'commissionableAmount',
					columnHeader: 'Commissionable Amount',
					dataFormatType: AppConstants.dataFormatTypes.currency,
					displayOrder: 7
				},
				{
					dataKey: 'amount',
					columnHeader: 'Amount',
					dataFormatType: AppConstants.dataFormatTypes.currency,
					displayOrder: 8
				}
			];
		this.selectedColumns =
			[
				...this.availableColumns
			];

		this.commonTable =
			{
				tableTitle: 'Agencies',
				dataKey: 'organizationId',
				exportable: true,
				tableHeight: {
					virtualPageSizeBased: true,
					minimumDrawerItemHeight: 9
				},
				actions: {
					filter: {
						quickFilters:
							[
								{
									label: this.quickfilters.all,
									value: this.quickfilters.all
								},
								{
									label: this.quickfilters.positiveAmount,
									value: this.quickfilters.positiveAmount
								},
								{
									label: this.quickfilters.negativeAmount,
									value: this.quickfilters.negativeAmount
								},
								{
									label: this.quickfilters.zeroAmount,
									value: this.quickfilters.zeroAmount
								},
								{
									label: this.quickfilters.commissionsAmount,
									value: this.quickfilters.commissionsAmount
								},
								{
									label: this.quickfilters.adjustmentsAmount,
									value: this.quickfilters.adjustmentsAmount
								},
								{
									label: this.quickfilters
										.directCommissionsAmount,
									value: this.quickfilters
										.directCommissionsAmount
								},
								{
									label: this.quickfilters
										.subCommissionsAmount,
									value: this.quickfilters
										.subCommissionsAmount
								}
							],
						selectedFilterValue: this.tableFilter
					}
				},
				objectSearch: {
					filter: this.tableFilter,
					orderBy: `date ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise:
					() =>
					{
						this.setfilteredDisbursements();

						return Promise.resolve(this.filteredDisbursements);
					},
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext:
					(commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
					{
						this.commonTableContext = commonTableContext;
						this.selectedAgency = commonTableContext.data;
					}
			};
	}

	/**
	 * Sets the filtered commission disbursements.
	 *
	 * @memberof CommissionDisbursementDetailsComponent
	 */
	private setfilteredDisbursements(): void
	{
		switch (this.commonTable.objectSearch.filter)
		{
			case this.quickfilters.positiveAmount:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement =>
							disbursement.amount > 0);
				break;
			case this.quickfilters.negativeAmount:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement =>
							disbursement.amount < 0);
				break;
			case this.quickfilters.zeroAmount:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement =>
							disbursement.amount === 0);
				break;
			case this.quickfilters.commissionsAmount:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement =>
							disbursement.isCommissionType === true);
				break;
			case this.quickfilters.adjustmentsAmount:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement =>
							disbursement.isAdjustmentType === true);
				break;
			case this.quickfilters.directCommissionsAmount:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement =>
							disbursement.isDirectCommissionType === true);
				break;
			case this.quickfilters.subCommissionsAmount:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement =>
							disbursement.isSubCommissionType === true);
				break;
			default:
				this.filteredDisbursements = this.disbursements;
		}
	}
}